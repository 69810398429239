.btn {
    padding: .25rem 1rem;
    border-radius: 5px;
    border-width: .075rem;

    &.small {
        font-size: .875rem;
    }

    &.btn-faded {
        opacity: .7;
    }

    &.btn-primary,
    &.btn-outline-primary:hover {
        background-color: $primary !important;
        border-color: $primary !important;
        color: $white;
    }


    &.btn-outline-secondary {
        border-color: $color_text_default;
        color: $color_text_default;

        &:hover {
            border-color: $primary;
            color: $primary;
            background-color: transparent;
        }

        &:focus {
            box-shadow: none !important
        }
    }

    &.btn-outline-primary {
        border-color: $primary;
        color: $primary;
    }

    &.btn-danger {
        background-color: $danger-light;
        border-color: $danger-light;
    }

    &.btn-sm {
        font-size: .75rem;
    }

    &.toggle,
    &.toggle-handle {
        border-radius: 0;

        &.btn-sm {
            min-height: 25px;
        }

        .btn-sm {
            font-size: 1rem;
            line-height: 1.2;
            padding: 2px 1rem;
        }

        .toggle-handle {
            padding: 2px .75rem;
        }

        .btn-danger {
            background-color: $danger;
            border-color: $danger;
        }

        .btn-success {
            background-color: $success;
            border-color: $success;
        }
    }

    // &.btn-fit-height {
    //     min-height: calc(2.6rem + 2px);
    // }

    // always red color danger

    &.btn-alert {
        background-color: #d24350 !important;
        border-color: #b4101f !important;
        color: $white;
    }

    &.btn-switch,
    &.btn-switch:hover {
        background-color: $color_constant !important;
        border-color: $color_constant !important;
        color: $white;
    }

    .btn-switch,
    .btn-switch:hover {
        background-color: $color_constant !important;
        border-color: $color_constant !important;
        color: $white;
    }
}

// .btn.btn-primary,
// .emrfrm .btn.btn-primary {
//     border-color: $primary !important;
//     border-width: 0.075rem;

//     &:hover {
//         background-color: $primary-border !important;
//         border-color: $primary-border !important;
//     }

//     &:focus {
//         box-shadow: none !important;
//     }
// }

// .btn.btn-outline-primary,
// .emrfrm .btn.btn-outline-primary {
//     border-width: 0.075rem;
//     font-weight: 500;

//     &:hover {
//         border-color: $primary !important;
//         // background-color: transparent !important;
//     }

//     &:focus {
//         box-shadow: none !important;
//     }
// }
.btn{
        &.btn-border{
        border:1px solid #ccc!important;
        &:hover{background:#eee}
    }
}


.btn-booking{
    &:hover{p{color:#fff!important}}
}