.datetimepicker:hover{
  border:1px solid #d9d9d9!important;
}


.table{
  height: 10px;
}

.emrfrm .form-control {
    // height: auto !important;
    position: relative;
    height: 2rem;
}

.bg-multi{
    background-color: #53A1DF !important;
}

.bg-emergency{
    background-color:#EE7476 !important;
}

.bg-opd{
    background-color:#ABCF93 !important;
}

.icon.arr-left{
    height: 15px !important;
    widows: auto !important;
    position: absolute;
left: 3px;
top: 8px;
transform:rotate(180deg) !important;
}
.icon.arr-right{
    height: 15px !important;
    widows: auto !important;
    position: absolute;
right: 9px;
top: 8px;
}

// accordian part

$cAccent: #3978EF;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);




.accordion-list{
    background: rgb(255,255,255) !important;
    background: #fff !important;
   border:1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  max-width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
  
  &__item{
    &+&{
       
      border: 1px solid $cLightGray;
    }
  }
}

.accordion-item{
    
  $self: &;
  background: rgb(255,255,255) !important;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(198,198,198,1) 100%) !important;

  &--opened{    
    #{ $self }__icon{
      transform: rotate(180deg);
    }
        
    #{ $self }__inner{
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
    
    #{ $self }__content{
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__line{
    background: rgb(255,255,255) !important;
    background: #eee !important;
    display: block;
    padding: 0.8rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: relative;
  }
  
  &__title{

    font-size: 14px;
    
    margin: 0;
    font-weight: 700;
    color: $cBlack;
  }
  
  &__icon{
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
    opacity: 0.6;
  }
  
  &__inner{
      
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }
  
  &__content{
      
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 1.2rem 1.2rem;
    background-color: #fff;
    border: 1px solid $cLightGray;
  }
  
  &__paragraph{
    margin: 0;
    font-size: 1rem;
    color: $cGray;
    font-weight: 300;
    line-height: 1.3;
  }
}