input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $white inset !important;
	-moz-box-shadow: 0 0 0 30px $white inset !important;
	-ms-box-shadow: 0 0 0 30px $white inset !important;
	-o-box-shadow: 0 0 0 30px $white inset !important;
	box-shadow: 0 0 0 30px $white inset !important;
}

input,
textarea,
select {
	filter: none;
}

// select option {
// 	background:white;
// 	color:black;
// 	box-shadow: inset 20px 20px #f00
//   }

//   select option:hover {
// 	color: #000;
// 	box-shadow: inset 20px 20px #00f;
//   }
.form-control,
.form-control1 {
	height: calc(2rem + 2px);
	padding: 0px 8px;
	border-color: $border-color;
	padding-left: 0.55rem;
	padding-right: 0.55rem;
	color: $default;

	font-size: .95rem;
	line-height: 1.3 !important;

	&:focus {
		border-color: #333;
		box-shadow: 0px 0px 0px $primary  !important;
		border: 1px solid #333 !important;
	}

	&:disabled {
		background: $white  !important;
		color: rgba($default, 1);
		border-color: rgba($border-color, 1);
		cursor: not-allowed;
	}

	&:disabled~label {
		//color: $text_muted !important;
		//color: $primary !important;
	}

	.filldata {
		&:disabled~label {
			color: $text_muted  !important;
		}
	}

	&[readonly] {
		background-color: $white  !important;
		opacity: 1;
		cursor: not-allowed;

		&~label {
			background-color: $white  !important;
			opacity: 1;
		}

		&:focus {
			border-color: #33333350 !important;
		}
	}
}

textarea.form-control {
	height: auto;
	min-height: 44px;
	padding-top: 0.575rem;
	overflow: hidden;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	background: url(../images/ic-arrow-down.svg) right/30px 14px transparent no-repeat !important;
	text-overflow: "";
	padding-right: 28px !important;

	&::-ms-expand {
		display: none;
	}
}

.form-group {
	margin-bottom: 0.75rem;
	position: relative;

	.alert-message {
		position: absolute;
		bottom: -16px;
		left: 14px;
		font-size: 0.75rem;
	}

	.errormsg {
		position: absolute;
		top: 0px;
		right: 0px;
		font-size: 0.825rem;
		background: #e9e5e5;
		border: solid 1px $error_border_color;
		padding: 0.4rem 0.5rem 0 0.55rem;
		width: 100%;
		height: 100%;
		z-index: 10;
		border-radius: 0.25rem;
		color: $error_color;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.errormsg-z {
		z-index: 9;
	}

	.left-marg {
		width: calc(100% - 40px);
	}

}

.form-group.customdrop {
	z-index: 999;
}

.form-group,
.form-label-group {
	position: relative;

	::-webkit-input-placeholder {
		color: transparent;
	}

	:-ms-input-placeholder {
		color: transparent;
	}

	::-ms-input-placeholder {
		color: transparent;
	}

	::-moz-placeholder {
		color: transparent;
	}

	::placeholder {
		color: transparent;
	}
}

.form-label-group>label,
.form-group>label {
	top: 5px;
	left: 13px;
	position: absolute;
	display: block;
	outline: none;
	z-index: 9;
	pointer-events: none;
	@include transition(0.1s);
	margin: 0px;

	&>span {
		color: $danger;
		display: inline-block;
		padding-left: 4px;
	}
}

.form-group.filldata>label,
.form-label-group.filldata>label {
	font-size: 12px;
	color: $primary;
	top: -10px;
	display: inline-block;
	padding: 0 2px;
	left: 8px;
	background: -moz-gradient(to top, $white 50%, rgba($white, 0) 0%);
	background: -webkit-gradient(to top, $white 50%, rgba($white, 0) 0%);
	background: linear-gradient(to top, $white 50%, rgba($white, 0) 0%);
}

// .form-group.filldata {
// 	.form-control {
// 		&:disabled ~ label {
// 			background: -moz-gradient(to top, rgba($white, 1) 50%, rgba($white, 0) 0%);
// 			background: -webkit-gradient(to top, rgba($white, 1) 50%, rgba($white, 0) 0%);
// 			background: linear-gradient(to top, rgba($white, 1) 50%, rgba($white, 0) 0%);
// 			// color: $primary !important;
// 			color: black;
// 		}
// 	}
// }

.form-group.input-group.filldata>label {
	top: -11px;
}

.form-group.input-group {
	border: solid 1px $border-color;
	border-radius: 0.25rem;
	@include transition(0.15s);

	.form-control {
		border: none !important;
		height: calc(2rem + 0px);
	}

	.form-control1 {
		border: none !important;
		height: calc(2rem + 0px);
	}

	.form-control1:focus {
		outline: none;
	}

	.input-group-addon {
		background: $white;
		padding: 0px 10px;
		position: relative;

		// height: calc(2rem - 0.1rem);
		.btn {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			height: calc(2rem - 0px);
		}

		i,
		select {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: relative;
			opacity: 0.8;
		}

		select {
			border-color: transparent;
			padding-right: 16px;
			outline: none;
			background-size: 12px 13px;

			&:focus {
				outline: none;
			}
		}

		&.bg-grey {
			background-color: $bg_grey;
			color: $white;
			margin-right: -1px;

			select {
				color: $white;
				background: url(../images/ic-arrow-down-white.svg) right/13px 12px transparent no-repeat !important;

				option {
					color: $default;
				}
			}
		}
	}

	&> :first-child {
		border-radius: 0.25rem 0 0 0.25rem !important;
	}

	.form-control~.input-group-addon {
		border-radius: 0 0.25rem 0.25rem 0;
		z-index: 11;
		line-height: 27px;
		// height: 39px;
	}

	&:focus-within {
		border-color: #333;
	}

	&.mobile-number {
		background: $white;

		.input-group-addon {
			position: relative;

			&:after {
				content: "";
				width: 1px;
				height: 16px;
				position: relative;
				top: 7px;
				background: $border-color;
				display: none;
			}
		}

		select {
			opacity: 0;
			border-color: transparent;
			padding-right: 15px;
			min-width: 62px;
			background-position: 42px 6px !important;
			background-size: 11px !important;
		}

		&.filldata {
			.input-group-addon:after {
				display: inline-block;
			}

			select {
				opacity: 1;
			}
		}
	}
}

.form-control-inline {
	height: calc(2em + 2px);
	padding: 0.375rem 0.75rem;
	font-size: .85rem;
	font-weight: 400;
	line-height: 1.5;
	outline: none;
	color: $default;
	background-color: #fff;
	border: 1px solid $border-color;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-checkbox {
	.custom-control-input {
		width: 1.2rem;
		height: 1.3rem;
		top: 0;

		&:checked {
			background: transparent !important;
		}
	}

	.custom-control-label {

		&::before {
			border-radius: 0.15rem;
			width: 1.2rem;
			height: 1.2rem;
			top: 0.05rem;
			border-color: $border-color  !important;
			border-radius: 3px !important;

		}
	}

	.custom-control-input:checked~.custom-control-label {
		&::before {
			border-color: $primary  !important;
			background-color: $white;
			border-radius: 3px !important;
		}

		&::after {
			background-image: none;
			left: -1.07rem;
			width: 0.4rem;
			height: 0.8rem;
			top: 0.2rem;
			border-bottom: 0.125rem solid $primary;
			border-right: 0.125rem solid $primary;
			transform: rotate(45deg);
			display: block;
		}
	}

	.custom-control-input:focus~.custom-control-label::before {
		box-shadow: 0px 0px 3px #333;
		outline: none;
		border: 1px solid #333 !important;
	}

	.custom-control-input:not(:disabled):active~.custom-control-label {
		&::before {
			background-color: transparent;
			border-color: $border-color;
		}
	}

	.custom-control-input:disabled:checked~.custom-control-label {
		&::before {
			background-color: rgba($white, 1);
			border-color: rgba($primary, 1) !important;
			opacity: 1 !important;

		}

		&::after {
			border-color: rgba($primary, 1);
			opacity: 1 !important;
		}
	}
}

// .custom-control-label{line-height: 18px!important;}

.custom-radio {
	.custom-control-input {
		width: 1.25rem;
		height: 1.25rem;
		top: 0.25rem;
	}

	.custom-control-label {
		&::before {
			border-radius: 50%;
			width: 16px;
			height: 16px;
			top: 3px;
			border-color: $border-color  !important;
		}
	}

	// .custom-control-input:not(:disabled):active ~ .custom-control-label {
	//  &::before {
	//  background-color: transparent;
	//  border-color: $border-color;
	//  }
	//  }
	.custom-control-input:checked~.custom-control-label {
		&::before {
			border-color: transparent !important;
			background-color: transparent;
		}

		&::after {
			// background-image: none;
			// background-color: $primary;
			background: url(../images/radio-on.png) center/16px 16px transparent no-repeat !important;
			width: 18px;
			height: 18px;
			// 		left: -18.8px;
			// top: 5.5px;
			// width: 9px;
			// height: 9px;
			// 		border-radius: 50%;
			// 		display: block;
			border: 0px;
			background-size: contain;
			background-repeat: no-repeat;
			top: 2px;
		}
	}



	.custom-control-input:focus~.custom-control-label::before {
		box-shadow: none;
		outline: none;
	}

	.custom-control-input:not(:disabled):active~.custom-control-label {
		&::before {
			background-color: transparent;
			border-color: $border-color;
		}
	}
}

.custom-control.mt-2 {
	margin-top: 0.4rem !important;
}

.select-addonn-left {
	display: flex;

	.input-group-addon {
		background-color: #999999;
		color: #fff;
		padding: 0px 10px;
		position: relative;
		height: calc(2rem + 2px);
		line-height: calc(2rem - 0.01rem);
		border-radius: 0.25rem 0px 0px 0.25rem;
	}

	.form-group {
		border-radius: 0rem 0.25rem 0.25rem 0 !important;

		.select2-selection,
		.select2-selection--single {
			border: 0px !important;
			height: calc(2rem - 0.14rem) !important;
			line-height: calc(2rem - 1rem) !important;
		}
	}
}

/* ############################ Button ############################################## */
.btn {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-width: 0.075rem;

	&.btn-primary {
		border-color: $primary;
		color: $white;
		background-color: $primary;

		&:hover,
		&:active,
		&:focus {
			border-color: $primary-hover;
			background-color: $primary-hover  !important;
		}
	}

	&.btn-light {
		border-color: $text_muted;
		color: $bg_dark;
		background-color: $bg_lightgrey;

		&:hover,
		&:active,
		&:focus {
			border-color: $text_muted;
			background-color: $bg_lightgrey  !important;
		}
	}

	&.btn-outline-primary {
		border-color: $primary;
		color: $primary;

		&:hover,
		&:active,
		&:focus {
			border-color: $primary-hover  !important;
			// color: $primary-hover !important;
			background-color: transparent !important;
		}
	}

	&.btn-outline-danger {
		&:focus {
			// 	border-color: #7a040e;
			// color: #7a040e!important;
		}

		&.active {
			color: white !important;
		}
	}


	&.btn-outline-new {
		border-color: $default;
		color: $default;

		&:hover,
		&:active,
		&:focus {
			background-color: $primary  !important;
			color: $white  !important;
			border-color: $primary  !important;

		}
	}

	&.btn-outline-secondary {
		border-color: $bg_dark;
		color: $bg_dark;

		&:hover,
		&:active,
		&:focus {
			border-color: $primary  !important;
			color: $primary  !important;
			background-color: transparent !important;
		}
	}

	&.btn-link-primary {
		border-color: transparent !important;
		color: $primary  !important;

		&:hover,
		&:active,
		&:focus {
			border-color: $primary  !important;
			color: $primary  !important;
			background-color: transparent !important;
		}
	}

	&.bg-white {

		&:hover,
		&:active,
		&:focus {
			background-color: $white  !important;
		}
	}
		 &:disabled {
		 	cursor: not-allowed;
		 }
}

.btn:focus,
.btn:active {
	box-shadow: none !important;
	outline: none !important;
}

/* ############################ Confirm Box #####################################*/
.confirm-dialog-box {
	width: auto;
	background-color: $white;
	padding: 1rem;
	position: absolute;
	z-index: 999;
	bottom: 0px;
	border-radius: 0.25rem;
	@include box-shadow(0, 0, 5px, 1px, 0.5);
	display: none;
}

.dropdown-item {
	&:active {
		background-color: #d24350;
	}

	padding: .45rem 1.5rem !important;
	border-bottom:1px solid #eee;

	&:hover,
	&:active {
		background: #f4f4f4 !important;
		color: $default !important;
	}
}

.dropdown-menu a:last-child {
	border: 0 !important;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
	background-color: transparent !important;
}

.btn-like-input {
	width: 100%;
	background: white;
	border: 1px solid #ccc;
	min-height: 32px;
	border-radius: 4px;
	text-align: left;
	font-size: .85rem;
	color: #333;
}

.mandatoryStar {
	label {
		&:after {
			position: absolute;
			content: ' *';
			top: 1px;
			color: $primary;
			margin-left: 5px;
		}
	}
}

#printBloodRequestContainer {
	.table {
		td {
			// padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.custom-checkbox-cricle {
	.custom-control-input {
		width: 1.2rem;
		height: 1.3rem;
		top: 0;
		z-index: 2;

		&:checked {
			background: transparent !important;
		}
	}

	.custom-control-label {

		&::before {
			border-radius: 0.15rem;
			width: 1.2rem;
			height: 1.2rem;
			top: 0.05rem;
			border-color: $border-color  !important;
			border-radius: 50% !important;

		}
	}

	.custom-control-input:checked~.custom-control-label {
		&::before {
			border-color: $success  !important;
			background-color: $success;
			border-radius: 50% !important;
		}

		&::after {
			background-image: none;
			left: -1.07rem;
			width: 0.4rem;
			height: 0.8rem;
			top: 0.2rem;
			border-bottom: 0.155rem solid $white;
			border-right: 0.155rem solid $white;
			transform: rotate(45deg);
			display: block;
		}
	}

	.custom-control-input:focus~.custom-control-label::before {
		box-shadow: 0px 0px 3px $success;
		outline: none;
		border: 1px solid #333 !important;
	}

	.custom-control-input:not(:disabled):active~.custom-control-label {
		&::before {
			background-color: transparent;
			border-color: $success;
		}
	}

	.custom-control-input:disabled:checked~.custom-control-label {
		&::before {
			background-color: rgba($white, 1);
			border-color: rgba($success, 1) !important;
			opacity: 1 !important;

		}

		&::after {
			border-color: rgba($success, 1);
			opacity: 1 !important;
		}
	}
}




input[type='radio']:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #d1d3d1;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
}

input[type='radio']:checked:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #ffa500;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
}