.gray-table {
    thead {
        th {
            text-align: left !important;
        }
    }

    tbody {
        tr {
            td {
                vertical-align: middle !important;
                background-color: #fff;
                color: #333333;
            }
        }
    }
}

.newsearchicon {
    width: 17px;
    height: auto;
}

.refresh-btn {
    border: solid 1px #ccc !important;
    // background-color: #e5e5e5;

    &:hover {
        background-color: #e5e5e5;
    }
}

.bluecard-new {
    background-color: #eaf3f7;
    padding: 1.5rem 1.5rem;
    border: solid 1px #eaf3f7;

    .form-control,
    .form-control1 {
        background-color: #fff !important;
    }
}

// attendancepopup
.medium-modal {
    .modal-header {
        padding: 1.5rem 1.5rem 0rem 1.5rem !important;
        font-size: 1.2rem;
    }

    .modal-body {
        padding: 1rem 2rem !important;
    }
}

// large modals
.large-modal-custom {
    min-width: auto !important;
    max-width: none !important;
    width: 85% !important;
    //min-height: auto !important;

    .modal-header {
        padding: 1.5rem 1.5rem 0rem 1.5rem !important;
        font-size: 1.2rem;
    }

    .modal-body {
        padding: 1rem 2rem !important;
    }
}


// orderdetails popup accordion

.pop-acc-card {
    border: none;

    .accordion-head {
        cursor: pointer;
        background-color: #ebebeb;
        color: #333;
        border: solid 1px #dddbdb;
        border-radius: 5px;
        position: relative;

        a {
            color: #333333;
            font-weight: bold;
        }

        .arrow-pop-acc {
            position: absolute;
            top: 30%;
            right: 20px;
            height: 14px;
            width: 13px;
            color: #666666 !important;
            transition: all 0.3s ease;
            transform: rotate(90deg);
        }

        [aria-expanded="true"] .arrow-pop-acc {
            position: absolute;
            top: 30%;
            right: 20px;
            height: 14px;
            width: 13px;
            color: #666666 !important;
            transition: all 0.1s ease;
            transform: rotate(270deg);
        }
    }

    .card-body {
        padding: 0.75rem 0.5rem;

        .container-fluid.timeline-container {
            box-shadow: none;
            padding: 0px;
            border-bottom: solid 1px #ddd;
        }

        .timelines {
            position: relative;

            li {
                list-style: none;
                float: left;
                width: 25%;
                position: relative;
                text-align: center;

                color: #666666;

                &:before {
                    content: '';
                    width: 7px;
                    height: 7px;
                    border: 3px solid #4caf50;
                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    margin: 0 auto 10px auto;
                    background: #fff;
                    color: #000;
                    transition: all ease-in-out .3s;
                    cursor: pointer;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #4caf50;
                    top: 3px;
                    left: -50%;
                    z-index: 99;
                }

                &:first-child:after {
                    content: none;
                }

                &.active-tl {
                    color: #555555;

                    &:before {
                        background: #4caf50;
                        color: #F1F1F1;
                    }
                }
            }
        }

    }
}

.upload-pdf-img {
    width: 28px;
    height: auto;
}

// tracking popup
.tracking {
    .timelines {
        position: relative;

        li {
            list-style: none;
            float: left;
            width: 25%;
            position: relative;
            text-align: center;

            color: #666666;

            &:before {
                content: '';
                width: 7px;
                height: 7px;
                border: 3px solid #4caf50;
                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                background: #fff;
                color: #000;
                transition: all ease-in-out .3s;
                cursor: pointer;
            }

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #4caf50;
                top: 3px;
                left: -50%;
                z-index: 99;
            }

            &:first-child:after {
                content: none;
            }

            &.active-tl {
                color: #555555;

                &:before {
                    background: #4caf50;
                    color: #F1F1F1;
                }
            }
        }
    }
}

// create care plan css

.create-plan-card {
    background-color: #fcfcfc;
    border: solid 1px #cccccc;
    border-radius: 2px;
    padding: 1rem;
}

.create-taskicon {
    width: 13px;
    height: auto;
}

// manage resource css
.rounded-user-img {
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    width: 32px;
    height: 32px;
}

// reschedule css
.rescheduleslot {
    width: 15%;
    text-align: center;
    padding: 0.5rem 0.15rem;
    margin: 0 1rem 1rem 0;
    color: #fff;
    border-radius: 10px;

    &.timeslot-available {
        background-color: transparent;
        border: 1px solid #d24350;
        color: rgb(24, 24, 24);
        cursor: pointer;

        &.selected {
            background-color: #d24350;
            color: #fff;
        }
    }

    &.timeslot-not-available {
        background-color: rgb(216, 114, 155);
        cursor: not-allowed;
    }
}

.btn-custom {
    background-color: #fff;
}


//add resource css
.bg_personal {
    background-color: #eaf3f7;
}

.border-radius {
    border-radius: 4px 0px 0px 4px !important;
}

.reg-tittle-box {
    border-right: solid 1px #8d8d8d;
    height: 100%;
    display: flex;
    align-items: center;
    width: 170px;
    position: relative;
}

.reg-tittle-box::after {
    content: "";
    display: block;
    top: 50%;
    right: -3.4%;
    position: absolute;
    border: 1px #8d8d8d;
    background: #eaf3f7;
    border-style: solid solid none none;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
}

.bg-after::after {
    background: #fff !important;
}

.afternone::after {
    display: none;
}

// attendance popup assign resource
.redbg-box {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: #d24350;
    margin-right: 5px;
}

.bg-green {
    background-color: #4caf50;
}


//Manage Booking Accordion table css

// table.fold-table {
//     width: 100%;
//     background-color: #fff;

//     .mainhead {
//         background-color: #8b8b8b;

//         th {
//             color: #fff;
//             font-weight: normal;
//             padding: 0.3rem 0.5rem !important;
//             vertical-align: top;
//             font-size: 0.95rem;

//             .filter-icon-custom {
//                 width: 13px;
//                 height: auto
//             }
//         }
//     }

//     tbody {
//         tr.view {
//             td {
//                 cursor: pointer;
//                 color: #333;
//                 font-weight: 400;
//                 background-color: #fff;
//                 border: none;
//                 border-bottom: solid 1px #eaeaea;
//                 padding: 0.35rem 0.75rem !important;
//                 font-size: 0.93rem;

//                 &:first-child {
//                     position: relative;
//                     padding-left: 25px !important;

//                     .arrowr {
//                         position: absolute;
//                         top: 30%;
//                         left: 8px;
//                         height: 14px;
//                         width: 13px;
//                         color: #666666 !important;
//                         transition: all 0.3s ease;
//                     }
//                 }

//                 &:last-child {
//                     padding: 0.35rem 0rem 0.35rem 0.35rem !important;
//                 }

//                 .phone-icon-custom {
//                     width: 14px;
//                     height: auto;
//                 }

//                 .status-badge {
//                     width: 31px;
//                     flex: 0 0 30px;
//                     height: 16px;
//                     display: inline-block;
//                     text-align: center;
//                     border-radius: 50px;
//                     color: #fff;
//                     font-size: 11px;
//                     font-weight: 500;
//                     margin-right: 10px;
//                     line-height: 17px;

//                 }

//                 .dots-padding {
//                     padding: 9px 7px 9px 10px;
//                 }

//                 .dots-icon {
//                     width: 12px;
//                     height: 17px;
//                 }

//                 // span {
//                 //     font-size: 0.938rem;
//                 // }

//                 // label {
//                 //     font-size: 0.938rem;
//                 // }
//             }

//             &:hover {
//                 background: #ccc;
//             }
//         }

//         tr.view.open {
//             background: #ebebeb;
//             color: #333;

//             td {
//                 &:first-child {
//                     &:before {
//                         transform: rotate(90deg);
//                         color: #333;
//                         top: 50%;
//                     }

//                     .arrowr {
//                         transform: rotate(90deg);
//                     }
//                 }

//                 border-bottom: solid 1px #fff !important;
//             }
//         }

//         tr.fold {
//             display: none;

//             th {
//                 color: #333;
//                 font-weight: normal;
//                 border: solid 1px #dedede;
//                 padding: 0.3rem 0.75rem !important;
//                 font-size: 0.9rem;
//                 vertical-align: top;

//                 .filter-icon-custom {
//                     width: 13px;
//                     height: auto
//                 }
//             }
//         }

//         tr.fold.open {
//             display: table-row;

//             td {
//                 padding: 0rem 1rem 0.75rem 1rem;
//                 border-bottom: solid 1px #eaeaea;

//                 .roundv-icon {
//                     width: 19px;
//                     height: 19px
//                 }

//                 .v-width {
//                     min-width: 29px;
//                     padding-right: 10px;
//                     ;
//                 }

//                 .flag-icon {
//                     width: 16px;
//                     height: auto;
//                 }

//                 .dots-icon {
//                     width: 12px;
//                     height: 17px;
//                 }

//                 .dots-padding-in {
//                     padding: 0px 4px 0px 5px;
//                 }
//             }
//         }
//     }
// }

// .dots-padding-in {
//     padding: 0px 4px 0px 5px;
// }

// .rowtable {
//     width: 100%;

//     thead {
//         background-color: #dedede;
//     }

//     tbody {
//         background-color: #fff2f3;
//         border: solid 1px #ede4e4;

//         tr {
//             >td {
//                 color: #333;
//                 border: solid 1px #ede4e4;
//                 padding: 0.4rem 0.7rem !important;
//                 font-size: 0.9rem;
//             }
//         }
//     }
// }

// .fold-content {
//     .rowtable {
//         tbody {
//             tr {
//                 td {
//                     padding: 0.4rem 0.7rem !important;
//                     font-size: 0.9rem;

//                     span {
//                         font-size: 0.9rem;
//                     }

//                     label {
//                         font-size: 0.9rem;
//                     }
//                 }
//             }
//         }
//     }
// }

// accordion table css end


.table-edit-icon {
    width: 17px;
    height: auto;
}

.table-del-icon {
    width: 15px;
    height: auto;
}

.bluecard {
    background-color: #eaf3f7;
    padding: 1rem 1.5rem;
    // border: solid 1px #b0c9d5;
    border: solid 1px #eaf3f7;
}

.whitecard {
    background-color: #fff;
    padding: 1rem 1.5rem;
}

.carddustwhite {
    background-color: #f2f2f2;
    padding: 1rem 1.5rem;
}

.btn-bg-white {
    background-color: #fff;
}

.addiconsize {
    width: 21px;
    height: 21px;
}

.gray-banner-card-homecare-homecare {
    background-color: #ededed;
    padding: 0.75rem 0.75rem;
    border: solid 1px #cccccc;
    border-radius: 5px;

    .banner-colwidth {
        color: #666666;
        width: 50px;
    }
}

.date-red {
    padding: 0 5px 1px;
    background-color: #d24350;
    color: #fff !important;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 3px;
}


// refund css
.refundtable {
    width: 100%;

    th {

        color: #333 !important;
        background: white;
        font-weight: 500 !important;
        font-size: 0.95rem;
        padding: 0.35rem 0.75rem;
        z-index: 1;
    }

    // tr {
    //     position: relative;
    //     color: #5b5b5b !important;
    //     font-weight: 400 !important;
    //     font-size: 14px;
    //     padding: 0.35rem 0.75rem;
    //     background: white;
    //     border-bottom: 1px solid #ebecf0;
    // }
    td {
        color: #333 !important;
        background: white;
        font-size: 0.938rem;
        padding: 0.35rem 0.75rem;
    }

    .ellipse-size {
        height: 18px;
        width: 7px;
    }
}

.selecttag-white {
    background-color: #fff !important;
}

// .homecare-refund{
//     min-height: calc(100vh - 300px) !important;
// }
.hide-browse {
    position: absolute;
    right: 0;
    opacity: 0;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;

}

.resorse-list-cont {
    position: absolute;
    top: 32px;
    width: 101%;
    background: white;
    z-index: 99999;
    left: -1px;
    border: 1px solid lightgray;
    border-radius: 5px;
    border-top: 0px;
    padding: 3px 0px;
}

.resorse-list-cont p {
    margin: 0px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    padding: 0px 8px
}

.text-error {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 32px;
}

.nopaddings {
    padding: 0rem 0.25rem !important;

    &.dropdown-toggle::after {
        display: none !important;
    }
}

.new-dropdown {
    .dropdown-item {
        padding: .45rem 0.75rem !important;
        border-bottom: 1px solid #eee;
    }
}

// white button container

// .btn-container {
//     background-color: #fff;
//     padding: 15px 20px;
// }

// .btn-outline-primary {
//     background-color: #fff !important;

//     &.small-btn {
//         font-size: 0.9rem !important;
//     }
// }

.text-errors {
    color: #d24350;
}

.ant-form-item-explain-error {
    color: #d24350;
    font-size: 0.8rem;
}

.ant-picker-dropdown {
    z-index: 100002 !important;
}

.fullWidth {
    width: 100% !important;

    .ant-picker {
        width: 100% !important;
        border: none;
    }
}

.sub-book-accordion {
    .card {
        border: none;
        border-bottom: none !important;

        .card-header {
            padding: 0rem !important;
            background-color: #fff !important;
            border-bottom: none !important;

            .padding-arrow {
                padding: 0.25rem 1.75rem;
                text-decoration: none;

                &:active {
                    text-decoration: none;
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    //right: 10px;
                    left: 10px;
                    top: 12px;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-bottom: 0.125rem solid #838383;
                    border-right: 0.125rem solid #838383;
                    transform: rotate(45deg);
                    cursor: pointer;
                }

                &.collapsed {
                    color: #5c5c5c;
                    font-size: 0.95rem;

                    &::after {
                        transform: rotate(-45deg);
                        top: 12px;
                        right: 10px;
                        border-color: #8c8c8c;
                    }

                }

                &[aria-expanded="true"] {
                    text-decoration: none;
                    color: #333333;
                    font-size: 0.95rem;
                    border: solid 1px #fff !important // border:none !important;
                }

            }
        }
    }

    .card-body-subtable {
        padding: 0.5rem 1.75rem;
    }
}
