.adv-search-frm{
    justify-content: center;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    .col-8{}
    h1{font-size: 3rem; font-weight: 300; color: $default;  font-family: 'Myriad Pro', Roboto, sans-serif;  margin-bottom: 1.5rem;
        strong{font-weight: 800;}
    }
    .display-magic{display: block; text-align: center}
    .form-group.input-group{ @include box-shadow(0,0,15px,0,0.1);
        .ic-search{max-height: 22px; padding-right: 0.8rem; border-right:solid 0.12rem #ccc!important;
            top: 50%; left: 50%; transform: translate(-50%, -50%); position:relative; opacity: 0.8;
        
        }
         .form-control{height: 3.5rem;}

         > label{top:18px; left: 60px; font-size: 1.05rem;}
         &.filldata {
            > label{top:-11px; left: 40px; font-size: 13px;}
         }

         .mic{padding-left: 1rem; padding-right: 2.25rem;
             i{position: absolute; padding-left: 0.75rem; font-size: 1.5rem; border-left:solid 0.12rem #ccc!important;}
         }
         .cross{ padding-right: 2.25rem;
            i{position: absolute; padding-left: 0.75rem; font-size: 1.5rem;}
        }
    }
    &.findrecord{
        
        .form-group,h1{ margin-bottom: 0px!important;}
        display: flex; height: auto; margin-top: 1rem;
        .col{max-width: 800px;}
        .col-8{flex:0 0 100%;}
        .display-magic{display: flex!important; align-items: center;
        .col-12{
            width: 200px; flex: 0 0 200px;
        }
        }

        justify-content: left!important;
        margin-bottom: 10px;
        .col-8{ margin-top: 0%; max-width: 100%; width: 100%;}
        h1{font-size: 1.5rem; margin-bottom: 0.75rem;}
        .form-group.input-group{
            .form-control{height: 2.6rem; font-size: 1.1rem;}
            > label{top:18px; left: 60px; font-size: .85rem;}
            &.filldata {
                > label{top:-11px; left: 40px; font-size: 13px;}
             }
        }
    }

    .conflict-form{
        label{
            left: 10px!important;
        }
    }
    .search_input{
        .form-label-group, .form-group{
            & > label{top:14px; font-size: 1rem;}
            &.filldata{
                & > label{top:-10px; font-size: 12px;}
            }
        }
        .form-control{height: 3.5rem!important}
        .conflict-form .form-control{height: 3.4rem!important}
        .react-datepicker__input-container:after{line-height: 3;}
        .btn{height: 52px;}
    }
    .form-group.input-group .errormsg{text-align:left!important; height:calc(100% + 2px)!important;}
    .errormsg{text-align:left!important; height:100%!important; white-space:normal}
}
.no_record_found{align-items: center; justify-content: center; 
    figure{max-width: 120px; margin: 0 auto;
        img{width: 100%; height: auto;}
    }
    h4{color: $default; font-weight: 400;}
}
.advsearch{display: none;}
#magic_search_wrap{}
#accordionMagicSearch{
    .card{margin-bottom: 0.6rem; border-bottom:1px solid rgba(0, 0, 0, 0.125); border-radius: 0.25rem;
        .card-header{font-size: 1.2rem; line-height: 1.2;
            a{text-decoration: none; display: block; position: relative; color: $default;
                &:after{content:'\e803'; font-family: "his-icon";transform: rotate(270deg);  display: block; position: absolute; right:0px; top:0px; z-index: 2; color:$default;}
                &.collapsed::after{content:'\e803'; transform: rotate(90deg);}
            }           
        }

        .card-body{padding: 0;
            &> .row{
                [class^="col"], [class*=" col"]{padding-top:0.5rem; padding-bottom:0rem;}
            }
            &> .row:first-child{
                [class^="col"], [class*=" col"]{padding-top:0.6rem; padding-bottom:0.4rem; font-weight: 500;}
            }
            p{margin-bottom: 0.5rem; line-height: 1.4;}
            .profile-image{width: 54px; height: 54px; margin-top:0.25rem;}
            .row:last-child{border-bottom:none!important;}
            .btn-outline-secondary{font-size: 0.9em;
                &:hover{background-color: $bg_primary!important; color: $white!important;}
            }
        }

/*

        &:nth-child(1){border-color: #e6c472;
            .card-header{background: #f9edcd; border-color: #e6c472;
                a{color:#8e613e;}
            }
            .card-body .row:first-child{border-bottom-color: #e6c472!important;}
        }

        &:nth-child(2){border-color: #afc49c;
            .card-header{background: #ddefc9; border-color: #afc49c;
               a{color:#778169;}
           }
           .card-body .row:first-child{border-bottom-color: #afc49c!important;}
       }

       &:nth-child(3){border-color: #8ac1c1;
            .card-header{background: #d9f1ef; border-color: #8ac1c1;
                a{color:#557d8f;}
            }
            .card-body .row:first-child{border-bottom-color: #8ac1c1!important;}
       }
       &:nth-child(4){border-color: #c2b7cd;
            .card-header{background: #e9e1f7; border-color: #c2b7cd;
                a{color:#7e6484;}
            }
            .card-body .row:first-child{border-bottom-color: #c2b7cd!important;}
        }
*/        
    }

}
#modal_magic_search .card .card-header a:after{display: none;}

.center-positioned{
    position: absolute; width: 96%; top: 50%; left: 50%; transform: translate(-50%, -50%); margin-left: 20px;
}
.conflict-form{
    .form-control{
        height: calc(2rem - 2px)!important;
    }
    &.filldata > label {
        left: 8px!important;
    }
}


.adv_search_btn{
    height: 53px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}