.ajax_loader {
	background: url(../images/loader.svg) no-repeat center center;
	min-height: 80px;
}

.myicon {
	font-style: normal;

	&:before {
		content: '';
		display: block;
		background: url("../images/icons.png") no-repeat;
		width: 100%;
		height: 100%;
	}

	&.email:before {
		background-position: 0px -58px;
	}

	&.hospital:before {
		background-position: -45px -50px;
	}

	&.member:before {
		background-position: -90px -50px;
	}

	&.experience:before {
		background-position: 0px -10px;
	}

	&.document:before {
		background-position: -45px -10px;
	}

	&.gid:before {
		background-position: -90px -10px;
	}



}

.burger-h {
	display: inline-block;
	width: 30px;
	height: 20px;
	background: url("../images/icons.png") no-repeat right -100px;
}

.process-i {
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../images/icons.png") no-repeat 0px -100px;
}

.icon-copy {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		//background: url("../images/ic-copy.svg") no-repeat;
		@include copy_icon($fill_primary);
		background-size: contain;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}

	&.active {
		&:before {
			@include copy_icon($fill_success);
		}
	}

	&.text-primary::before {
		@include copy_icon($fill_secondary);
	}
}

.icon_view {
	font-style: normal;
	width: 20px;
	height: 20px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include view_icon($fill_primary);
		background-repeat: no-repeat
	}

	&.text-primary {
		&:after {
			@include view_icon($fill_secondary);
		}
	}
}


.icon_phone {
	font-style: normal;
	width: 20px;
	height: 20px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include phone_icon($fill_primary);
		background-repeat: no-repeat
	}
}

.icon_star {
	font-style: normal;
	width: 20px;
	height: 20px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include star_icon($fill_primary);
		background-repeat: no-repeat
	}

	&.favourite {
		&:after {
			@include fillstar_icon('fb8805')
		}
	}
}

.icon_video {
	font-style: normal;
	width: 25px;
	height: 15px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include video_icon('000');
		background-repeat: no-repeat
	}
}
.icon_opd {
	font-style: normal;
	width: 25px;
	height: 15px;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		@include opd_icon('000');
		background-repeat: no-repeat
	}
}
.icon_delete,
.inner_delete {
	font-style: normal;
	width: 16px;
	height: 18px;
	display: inline-block;

	&:after {
		opacity: 0.9;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: url(../images/ic-delete.svg) no-repeat center center;
		background-size: contain;
	}
}

.icon-home {
	display: inline-block;
	width: 20px;
	height: 20px;

	&:before {
		content: '';
		display: block;
		@include home_icon($fill_black);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}



.icon-c-cancel {
	position: relative;
	border: solid 0.12rem #ccc;
	border-radius: 50%;
	display: block;
	width: 1.75rem;
	height: 1.75rem;

	&::before,
	&::after {
		content: '';
		width: 1rem;
		height: .12rem;
		background: rgba(0, 0, 0, .3);
		display: block;
		position: absolute;
		transform: rotate(45deg);
		top: 0.75rem;
		left: 0.25rem;
	}

	&::after {
		transform: rotate(135deg);
	}
}

.text-secondary {
	.icon-c-cancel {
		border-color: $primary;

		&::before,
		&::after {
			background-color: $primary;
		}
	}
}

.icon-pause {
	display: inline-block;
	width: 15px;
	height: 15px;

	&:before {
		content: '';
		display: block;
		@include pause_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}
.icon-minus{display: inline-block; width: 26px; height: 26px;
	&:before{content: ''; display: block;
		@include minus_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%; height: 100%;
	}
}
.icon-anti-clock {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include anti_clock_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

// .icon-clock {
// 	display: inline-block;
// 	width: 20px;
// 	height: 20px;

// 	&:before {
// 		content: '';
// 		display: block;
// 		@include clock_icon($fill_black);
// 		background-repeat: no-repeat;
// 		background-size: cover;
// 		width: 100%;
// 		height: 100%;
// 	}
// }


.icon-doc-copy {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include doc_copy_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-print {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include print_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-print {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include print_icon($fill_black);
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}
}

.icon-edit {
	display: inline-block;
	width: 20px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		opacity: 0.7;
		@include edit_icon($fill_black);
		background-repeat: no-repeat;
		background-size: contain;
		width: 100%;
		height: 100%;
	}
}

.icon-calculator {
	display: inline-block;
	width: 30px;
	height: 32px;

	&:before {
		content: '';
		display: block;
		@include calculator_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-calendar {
	display: inline-block;
	width: 22px;
	height: 22px;

	&:before {
		content: '';
		display: block;
		opacity: 0.75;
		@include calendar_icon($fill_secondary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-plus {
	display: inline-block;
	width: 26px;
	height: 26px;

	&:before {
		content: '';
		display: block;
		@include plus_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon_substitutes {
	display: inline-block;
	width: 25px;
	height: 25px;

	&:before {
		content: '';
		display: block;
		@include substitutes_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon_info {
	display: inline-block;
	width: 24px;
	height: 24px;

	&:before {
		content: '';
		display: block;
		@include info_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-circle-left {
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: $secondary;
	border-radius: 50%;

	&:before {
		content: '';
		display: block;
		position: relative;
		top: 0.375rem;
		left: 4px;
		@include arrow_icon($fill_white);
		background-repeat: no-repeat;
		background-size: cover;
		width: 45%;
		height: 45%;
		transform: rotate(90deg);
	}

	&:hover {
		background-color: $bg_dark;
	}
}

.icon-circle-right {
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: $secondary;
	border-radius: 50%;

	&:before {
		content: '';
		display: block;
		position: relative;
		top: 0.375rem;
		left: 7px;
		@include arrow_icon($fill_white);
		background-repeat: no-repeat;
		background-size: cover;
		width: 45%;
		height: 45%;
		transform: rotate(-90deg);
	}

	&:hover {
		background-color: $bg_dark;
	}
}

.icon-severity-low {
	display: inline-block;
	min-width: 18px;
	width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_low);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: relative;
		top: 2px;
	}
}

.icon-severity-medium {
	display: inline-block;
	width: 18px;
	min-width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_medium);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: relative;
		top: 2px;
	}
}

.icon-severity-high {
	display: inline-block;
	width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_high);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: relative;
		top: 2px;
	}
}

.alert__iconSeverity--high {
	.icon-severity-high {
		display: inline-block;
		width: 18px;
		height: 16px;
	
		&:before {
			content: '';
			display: block;
			@include severity_icon($fill_severity_high);
			background-repeat: no-repeat;
			background-size: cover;
			width: 100%;
			height: 100%;
			position: relative;
			top: -2px;
		}
	}
}

.icon-severity-gray {
	display: inline-block;
	width: 18px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include severity_icon($fill_severity_gray);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}


.icon-chart {
	display: inline-block;
	width: 26px;
	height: 26px;

	&:before {
		content: '';
		display: block;
		@include chart_icon;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}

}

.icon-addfile {
	display: inline-block;
	width: 18px;
	height: 18px;

	&:before {
		content: '';
		display: block;
		@include addfile_icon($fill_secondary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-move {
	display: inline-block;
	width: 16px;
	height: 16px;

	&:before {
		content: '';
		display: block;
		@include move_icon($fill_secondary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-medinfo {
	display: inline-block;
	width: 24px;
	height: 28px;

	&:before {
		content: '';
		display: block;
		@include medinfo_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}







// custom-icons

[class*="his-icon-"],
[class^=his-icon-] {
	position: relative;
	display:inline-block &:before {
		content: '';
		display: block;
		position: relative;
		width: 20px;
		height: 20px
	}
}
// @font-face {
// 	font-family: 'his-icon';
// 	src: url('../fonts/his-icon.eot');
// 	src: url('../fonts/his-icon.eot#iefix') format('embedded-opentype'), url('../fonts/his-icon.woff') format('woff'), url('../fonts/his-icon.ttf') format('truetype'), url('../fonts/his-icon.svg#his-icon') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }
.his-icon {
	font-family: "his-icon"!important;
	font-style: normal;
	font-weight: normal;
	speak: never;
	display: inline-block;
	text-decoration: inherit;
	font-size: 1rem;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}










@font-face {
    font-family: 'his-icon';
    // src: url('../font/his-icon.eot?59525108');
     src: url('../font/his-icon.eot');

	src: url('../font/his-icon.eot?59525108#iefix') format('embedded-opentype'),
         url('../font/his-icon.woff2?59525108') format('woff2'),
         url('../font/his-icon.woff?59525108') format('woff'),
         url('../font/his-icon.ttf?59525108') format('truetype'),
         url('../font/his-icon.svg?59525108#his-icon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'his-icon';
      src: url('../font/his-icon.svg?59525108#his-icon') format('svg');
    }
  }
  */
   
  //  [class^="icon-"]:before, [class*=" icon-"]:before {
  //   font-family: "his-icon";
  //   font-style: normal;
  //   font-weight: normal;
  //   speak: never;
   
  //   display: inline-block;
  //   text-decoration: inherit;
  //   width: 1em;
  //   margin-right: .2em;
  //   text-align: center;
  //   /* opacity: .8; */
   
  //   /* For safety - reset parent styles, that can break glyph codes*/
  //   font-variant: normal;
  //   text-transform: none;
   
  //   /* fix buttons height, for twitter bootstrap */
  //   line-height: 1em;
   
  //   /* Animation center compensation - margins should be symmetric */
  //   /* remove if not needed */
  //   margin-left: .2em;
   
  //   /* you can be more comfortable with increased icons size */
  //   /* font-size: 120%; */
   
  //   /* Font smoothing. That was taken from TWBS */
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
   
  //   /* Uncomment for 3D effect */
  //   /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  // }
   
  .icon-search:before { content: '\e800'; } /* '' */
  .icon-ok:before { content: '\e801'; } /* '' */
  .icon-file:before { content: '\e802'; } /* '' */
  .icon-right-open-big:before { content: '\e803'; } /* '' */
  .icon-fall-risk-1:before { content: '\e804'; } /* '' */
  .icon-emp:before { content: '\e805'; } /* '' */
  .icon-ok-circled:before { content: '\e806'; } /* '' */
  .icon-ok-circled2:before { content: '\e807'; } /* '' */
  .icon-trash-empty:before { content: '\e808'; } /* '' */
  .icon-check:before { content: '\e809'; } /* '' */
  .icon-up-open-big:before { content: '\e80a'; } /* '' */
  .icon-up:before { content: '\e80b'; } /* '' */
  .icon-down:before { content: '\e80c'; } /* '' */
  .icon-left:before { content: '\e80d'; } /* '' */
  .icon-right:before { content: '\e80e'; } /* '' */
  .icon-left-open-big:before { content: '\e80f'; } /* '' */
  .icon-down-open-big:before { content: '\e810'; } /* '' */
  .icon-arrows-cw:before { content: '\e811'; } /* '' */
  .icon-down-circle:before { content: '\e812'; } /* '' */
  .icon-left-circle:before { content: '\e813'; } /* '' */
  .icon-right-circle:before { content: '\e814'; } /* '' */
  .icon-star-half:before { content: '\e815'; } /* '' */
  .icon-ic-calendar:before { content: '\e816'; } /* '' */
  .icon-bell:before { content: '\e817'; } /* '' */
  .icon-ic-bed:before { content: '\e818'; } /* '' */
  .icon-heart:before { content: '\e819'; } /* '' */
  .icon-heart-empty:before { content: '\e81a'; } /* '' */
  .icon-videocam:before { content: '\e81b'; } /* '' */
  .icon-mail:before { content: '\e81c'; } /* '' */
  .icon-linkedin:before { content: '\e81d'; } /* '' */
  .icon-twitter:before { content: '\e81e'; } /* '' */
  .icon-facebook:before { content: '\e81f'; } /* '' */
  .icon-youtube:before { content: '\e820'; } /* '' */
  .icon-email:before { content: '\e821'; } /* '' */
  .icon-googleplay:before { content: '\e822'; } /* '' */
  .icon-minus:before { content: '\e823'; } /* '' */
  .icon-plus:before { content: '\e824'; } /* '' */
  .icon-up-circle:before { content: '\e825'; } /* '' */
  .icon-star:before { content: '\e826'; } /* '' */
  .icon-star-empty:before { content: '\e827'; } /* '' */
  .icon-plus-circle:before { content: '\e828'; } /* '' */
  .icon-minus-circle:before { content: '\e829'; } /* '' */
  .icon-calculator:before { content: '\e82a'; } /* '' */
  .icon-ic-calculator:before { content: '\e82b'; } /* '' */
  .icon-ic-copy:before { content: '\e82c'; } /* '' */
  .icon-ic-edit:before { content: '\e82d'; } /* '' */
  .icon-plus-1:before { content: '\e82e'; } /* '' */
  .icon-plus-circle-1:before { content: '\e82f'; } /* '' */
  .icon-minus-circle-1:before { content: '\e830'; } /* '' */
  .icon-cancel-circled-outline:before { content: '\e831'; } /* '' */
  .icon-pin:before { content: '\e832'; } /* '' */
  .icon-ic-notification_red:before { content: '\e833'; } /* '' */
  .icon-shuffle:before { content: '\e834'; } /* '' */
  .icon-spin3:before { content: '\e835'; } /* '' */
  .icon-lock:before { content: '\e836'; } /* '' */
  .icon-lock-open:before { content: '\e837'; } /* '' */
  .icon-pencil-1:before { content: '\e838'; } /* '' */
  .icon-resize-full-alt:before { content: '\e839'; } /* '' */
  .icon-indent-left:before { content: '\e83a'; } /* '' */
  .icon-indent-right:before { content: '\e83b'; } /* '' */
  .icon-upload:before { content: '\e83c'; } /* '' */
  .icon-attention:before { content: '\e83d'; } /* '' */
  .icon-attention-circled:before { content: '\e83e'; } /* '' */
  .icon-link:before { content: '\e83f'; } /* '' */
  .icon-attach:before { content: '\e840'; } /* '' */
  .icon-info:before { content: '\e841'; } /* '' */
  .icon-user:before { content: '\e842'; } /* '' */
  .icon-attach-1:before { content: '\e843'; } /* '' */
  .icon-pencil-2:before { content: '\e844'; } /* '' */
  .icon-tags:before { content: '\e845'; } /* '' */
  .icon-eye:before { content: '\e846'; } /* '' */
  .icon-eye-off:before { content: '\e847'; } /* '' */
  .icon-lock-1:before { content: '\e848'; } /* '' */
  .icon-location:before { content: '\e849'; } /* '' */
  .icon-search-1:before { content: '\e84a'; } /* '' */
  .icon-pinboard:before { content: '\e84b'; } /* '' */
  .icon-clock:before { content: '\e84c'; } /* '' */
  .icon-download:before { content: '\e84d'; } /* '' */
  .icon-spin5:before { content: '\e84e'; } /* '' */
  .icon-spin4:before { content: '\e84f'; } /* '' */
   .icon-cancel:before { content: '\e850'; } /* '' */
  .icon-ccw:before { content: '\e851'; } /* '' */
  .icon-cw:before { content: '\e852'; } /* '' */
  .icon-arrows-ccw:before { content: '\e853'; } /* '' */
  .icon-camera:before { content: '\e854'; } /* '' */
  .icon-cancel-circled:before { content: '\e855'; } /* '' */
  .icon-down-1:before { content: '\e856'; } /* '' */
  .icon-up-1:before { content: '\e857'; } /* '' */
  .icon-mic-off:before { content: '\e858'; } /* '' */
  .icon-camera-1:before { content: '\e859'; } /* '' */
  .icon-folder:before { content: '\e85a'; } /* '' */
  .icon-ic-vip:before { content: '\e85b'; } /* '' */
  .icon-ic-hand:before { content: '\e85c'; } /* '' */
  .icon-down-open:before { content: '\f004'; } /* '' */
  .icon-up-open:before { content: '\f005'; } /* '' */
  .icon-right-open:before { content: '\f006'; } /* '' */
  .icon-left-open:before { content: '\f007'; } /* '' */
  .icon-move:before { content: '\f047'; } /* '' */
  .icon-mic:before { content: '\f048'; } /* '' */
  .icon-ok-circled-1:before { content: '\f06d'; } /* '' */
  .icon-info-circled:before { content: '\f085'; } /* '' */
  .icon-link-ext:before { content: '\f08e'; } /* '' */
  .icon-menu:before { content: '\f0c9'; } /* '' */
  .icon-sort-down:before { content: '\f0dd'; } /* '' */
  .icon-sort-up:before { content: '\f0de'; } /* '' */
  .icon-bell-alt:before { content: '\f0f3'; } /* '' */
  .icon-doc-text:before { content: '\f0f6'; } /* '' */
  .icon-angle-double-left:before { content: '\f100'; } /* '' */
  .icon-angle-double-right:before { content: '\f101'; } /* '' */
  .icon-star-half-alt:before { content: '\f123'; } /* '' */
  .icon-unlink:before { content: '\f127'; } /* '' */
  .icon-lock-open-alt:before { content: '\f13e'; } /* '' */
  .icon-link-ext-alt:before { content: '\f14c'; } /* '' */
  .icon-dollar:before { content: '\f155'; } /* '' */
  .icon-toggle-off:before { content: '\f204'; } /* '' */
  .icon-toggle-on:before { content: '\f205'; } /* '' */
  .icon-heartbeat:before { content: '\f21e'; } /* '' */
  .icon-calendar-plus-o:before { content: '\f271'; } /* '' */
  .icon-calendar-minus-o:before { content: '\f272'; } /* '' */
  .icon-calendar-times-o:before { content: '\f273'; } /* '' */
  .icon-calendar-check-o:before { content: '\f274'; } /* '' */
  .icon-user-circle:before { content: '\f2bd'; } /* '' */
  .icon-user-circle-o:before { content: '\f2be'; } /* '' */
  .icon-pinterest:before { content: '\f312'; } /* '' */


  
.icon-jpg {
	display: inline-flex;
	width: 24px;
	height: 28px;

	&:before {
		content: '';
		display: block;
		@include jpg_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}

.icon-pdf {
	display: inline-flex;
	width: 24px;
	height: 28px;

	&:before {
		content: '';
		display: block;
		@include pdf_icon($fill_primary);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}
