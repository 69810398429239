.table{
  width: 100%;
}

      .border-red{
          border: 1px solid #af4a53;
      }
  .span{
      position: relative;
                                                      border-radius: 50%;
                                                      background: #fff;
                                                      width: 26px;
                                                      height: 26px;
                                                      border: solid 1px #ccc;
                                                      text-align: center;
                                                      vertical-align: middle;
                                                      display: inline-block;
                                                      margin-left: 0.25rem;
  }
  
  /*---------ACTIVE COMPLAINTS-----------------*/
  .active_complaints tbody{
  max-height: 148px;
  overflow-y: auto;
  display: block; 
  
  height: 148px;
  }
  .active_complaints tbody td{
      width: 100%;
      display: inline-block;
       /* display: table; */
  }
  .active_complaints tbody td input{
      display: inline;
  }
/*---------ACTIVE COMPLAINTS-----------------*/



 .clinical_forms  tbody,
  .existing_conditions tbody,
  .family_history tbody,
  .events_in_24 tbody,
   .surgical_history tbody{
  max-height: 151px;
  overflow-y: auto;
  display: block; 
  height: 151px;
  }
  .clinical_forms  tbody{
    max-height: 138px;
    overflow-y: auto;
    display: block; 
    height: 138px;
  }
  .active_medication tbody{
    max-height: 138px;
    overflow-y: auto;
    display: block; 
    height: 138px;
  }
  .latest_vitals tbody{
      max-height: 162px;
      overflow-y: auto;
      display: block; 
      height: 162px; 
  }
  .latest_vitals tbody tr,
  .active_medication tbody tr,
   .existing_conditions tbody tr,
   .family_history tbody tr,
   .events_in_24 tbody tr, 
   .surgical_history tbody tr,
   .social_history tbody tr{
      width: 100%;
       display: table;
  }
  .clinical_forms  tbody tr{
    width: 100%;
    display: table;
    
   }
  
  .active_diagnosis tbody{
      max-height: 160px;
  overflow-y: auto;
  display: block; 
  
  height: 160px;

  }

  .active_diagnosis tbody tr{
      width: 100%;
       display: table;

  }
  .current_orders tbody{
      max-height: 138px;
  overflow-y: auto;
  display: block; 
  
  height: 138px;

  }
  .current_orders  tbody tr{
      width: 100%;
      display: table;
  } 
  /* .active_medication tbody{
      max-height: 148px;
  overflow-y: auto;
  display: block; 
  
  height: 148px;

  } */
  .active_medication  tbody tr{
      width: 100%;
       

  } 

  .social_history tbody{
      max-height: 142px;
      overflow-y: auto;
      display: block; 
      height: 142px;
  }


  .family_history tbody{
      max-height: 128px;
  overflow-y: auto;
  display: block; 
  
  height: 128px;
  
  }
  .family_history tbody tr{
      width: 100%;
       display: table;
  }
  
/*---------ORDERS ORDER-----------------*/
  
  .orders_order table{
      height: 140px;
  }
  .orders_order tbody{
      height:130pxpx;
      
      display: block;
      overflow-y: auto;
      max-height: 130px;
  }
  .orders_order tbody tr{
      width: 100%;
       display: table;
  }
/*---------ORDERS ORDER-----------------*/
  .active_allergy tbody{
      max-height: 149px;
  overflow-y: auto;
  display: block; 
  
  height: 149px;
  }
  .active_allergy tbody tr {
      
      width: 100%;
      display: table;
  }
  .active_allergy tbody tr th td{
      width: 120px;
  }
 .table-bordered-right{border-right:solid 1px #dee2e6;}


 .text-secondary a{
     color: #d24350;
 } 
 .text-primary a{
    color: #d24350;
 }
 .text-primary{
    color: #d24350;
 }
 .text-secondary a:hover{
    color: #d24350;
} 
.text-primary a:hover{
   color: #d24350;
}
/* .btn.btn-outline-primary {
    border-color: #f44336;
    color: #f44336;
} */

.text-herd:hover{
   background-color: #9e9e9e24;;
}
.text-herd{
    padding: 0.4rem 0.5rem;
    line-height: 1.5;
}

.btnSize{
  width: 160.69px;
}
