//css for Lab Dashboard mhea lims

.labdashboard_maintabs {

    //display: block;
    .nav-item {
        flex-grow: 1;
        margin-right: 1.3rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .nav-link {
        border: solid 1px #E4E3E7;
        background-color: #FBFBFD;
        border-radius: 7px;

        .heading {
            color: #333333;
            font-weight: bold;
            font-size: 1.1rem;
        }

        .sub_number {
            color: #d24350;
            font-weight: bold;
            font-size: 1.1rem;
        }

        &:hover {
            text-decoration: none;
            border: solid 1px #E4E3E7;
        }

        &.active {
            background-color: #FFFFFF;
            border: solid 1px #D4B5DC
        }
    }
}

.card_graph {
    background-color: #fff;
    border: solid 1px #d8d8d8;
    border-radius: 7px;
    padding: 0.75rem 0.75rem;

    .graph_card_heading {
        color: #333333;
        font-weight: bold;
    }
}


//****************************************************************************

.doughnut_chart {
    height: 300px;

    canvas {
        height: 300px;
        width: auto;
    }
}
.small_doughnut_bar_chart {
    height: 293px;

    canvas {
        height: 293px;
        width: auto;
    }
}
.bar_chart{
    height: 300px;
        canvas {
            height: 300px;
            width: auto;
        }
}
.big_bar_chart{
    height: 400px;
        canvas {
            height: 400px;
            width: auto;
        }
}
.limsDashboard{  
    // height: calc(100vh - 200px);
    background: #f2f1f8;
    width: calc(100% + 10px);
    padding-right: 10px;
}
.white_card_lab{
    background-color: #fff;
        border: solid 1px #d8d8d8;
        border-radius: 7px;
        padding: 0.75rem 0.75rem;
        .heading{
            color:#535353;
            font-weight: bold;
            font-size: 1.1rem;
        }
        .numberred{
            color:#c24e54;
            font-weight: bold;
            font-size: 1.1rem;
        }
}
.pie_chart{
    height: 400px;
    
        canvas {
            height: 400px;
            width: auto;
        }
}
.table_pink_labdash{
    thead{
        tr{
            th{
                background-color: #F8E8E8;
                padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
                color: #333;
                    font-weight: normal;
                    border: solid 1px #F8E8E8;
                    font-size: 0.9rem;
                    vertical-align: top;
                    text-align: left;
            }
        }
    }
        tbody{
            tr{
                td{
                    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
                    color:#333;
                    font-size: 0.9rem;
                    border-bottom: solid 1px #d8d8d8 !important;
                    border:none ;
                    &:first-child{
                        border-left:solid 1px #d8d8d8;
                    }
                    &:last-child {
                        border-right: solid 1px #d8d8d8 !important;
                    }
                }
            }
        }
    

    .dots-padding {
            padding: 9px 11px 9px 0px;
        }
    
        .dots-icon {
            width: 12px;
            height: 17px;
        }

}
.widget_antpop{
    width: 300px;
    .ant-popover-inner-content{
        padding:0rem;
    }
        .widget_drop {
            padding: 0.75rem 0.85rem;
            border-bottom: solid 1px #ccc;
            &:last-child{
                border:none;
            }
        }
}
.bar-graph-body{
    .bar-tab{
        display: inline;
        padding: 12px 12px;
        color:#535353;
        font-weight: bold;
        @media screen and (max-width: 1780px) {
                display: inline-block;
        }
    }
    .line-row{
        margin-bottom: 20px;
        &:first-child{
            margin-bottom: 30px;
        }
    }
    .line-bar.tabbed{
        margin-top:8px !important;
    }
    .line-label{
        color:#535353;
    }
    .source-bubble{
        height:9px;
        width:9px;
        border-radius: 50% !important;
    }
    .line-pointer{
        left:-3px;
        height:8px;
        width:8px;
    }
    .bar-chart-count{
        padding:0px;
        height:27px;
        width:22px;
        text-align: center;
        vertical-align: middle;
        padding-top: 4px;
        font-weight: bold;
    }
}

 