.modal {
	.modal-dialog {
		&.modal-md {
			max-width: 700px;
		}

		&.modal-xl {
			max-width: 1180px;
		}

		.modal-header {
			padding-bottom: 0;
			border: none;

			.row {
				width: 100%;
			}

			.close {
				font-size: 40px;
				font-weight: 300;
				z-index: 999;
				padding: .5rem 1.5rem;

				&:focus {
					outline: none;
				}

				.icon-cancel {

					&::before,
					&::after {
						width: 1.3rem;
					}
				}
			}

			.modal-title {
				font-size: 1.125rem;
			}
		}
	}

}

.chart-modal-single {
	.modal-dialog {
		max-width: 600px;

		.modal-content {
			border-radius: 0.75rem;
		}

		.modal-header {
			padding-bottom: 0;
			border-bottom: none;

			.modal-title {
				font-size: 1.125rem
			}

			.close {
				font-size: 40px;
				font-weight: 300;
				z-index: 999;
				padding: 0.5rem 1rem;
			}
		}
	}

	.text-heading {
		font-size: 1.125rem;
	}


	.chart_filter {
		margin: 10px auto 10px;
	}
}

.modal-single {
	.modal-content {
		border-radius: 0;
		background: #f7f7f7
	}

	.modal-header {
		padding-bottom: 0;

		.modal-title {
			font-size: 1.125rem
		}

		.close {
			font-size: 40px;
			font-weight: 300;
		}
	}

	.text-heading {
		font-size: 1.125rem;
	}

	dl {
		display: block;
		margin: 0px;
		padding: 0px;

		dt {
			display: inline-block;
			width: 30%;
			text-transform: uppercase;
			color: #999;
			font-weight: 400;
		}

		dd {
			display: inline-block;
			width: 60%;
			position: relative;
			padding-left: 6%;

			&:before {
				content: ':';
				display: inline-block;
				position: absolute;
				left: 0%
			}

			i {
				display: inline-block;
				width: 14px;
				height: 14px;
				border: solid 1px #ccc;
				margin-right: 6px;
				border-radius: 50%;
				background: #fff;
			}

			i.active {
				background: #00a950;
				border-color: #00a950
			}
		}
	}
}

.chart-modal-trend {
	.modal-dialog {
		max-width: 1200px;
		width: 94%;

		.modal-header {
			border-bottom: none;

			.close {
				font-size: 40px;
				font-weight: 300;
				z-index: 999;
				padding: .5rem 1rem;
			}
		}

		.modal-body {
			padding: 1rem 1.5rem
		}
	}
}

.modal-socialHx-width {
	.modal-header {
		border-bottom: none;

		.modal-title {
			font-size: 1.125rem;
		}

		.close {
			font-size: 40px;
			font-weight: 300;
			z-index: 999;
			padding: .5rem 1rem;
		}
	}

	.modal-dialog {
		max-width: 750px;
	}

}

.modal-max-width {
	.modal-dialog {
		max-width: 600px;

		.modal-content {
			border-radius: 0.75rem;
		}
	}


	.modal-header {
		padding-bottom: 0;
		border-bottom: none;

		.modal-title {
			font-size: 1.125rem
		}

		.close {
			font-size: 40px;
			font-weight: 300;
			padding: .5rem 1rem;
		}
	}

	.modal-body {
		padding-top: 0
	}
}

.chart-modal-trend {
	.modal-header .modal-title {
		padding-left: 0;
	}

	.modal-body {
		max-height: 480px;
		overflow-y: auto;
	}
}


#view_prescription .modal-body {
	max-height: 500px;
	overflow-y: scroll;
}

#modal_drugs_list {
	display: none;

	.col-12 {
		max-height: 164px;
		overflow-y: auto;
	}

	table tbody:nth-child(even) {
		background-color: #f6f6f6;
	}
}

.instruction-wrap {
	max-height: 500px;
	overflow-y: auto;
}

.ant-modal-header {
	border-bottom: none;
	padding-bottom: 0px;
}

.ant-modal-close {
	outline: none !important;
}