body,
html {
	font-family: Roboto, sans-serif !important;
	font-size: 0.95rem;
	min-height: 100vh;
	color: $default-color;
}

.overflow-visible {
	overflow: visible !important;
}

.line-height {
	line-height: 22px !important
}

.border {
	border-top: 1px solid #ccc !important;
}
// autocomplete autosuggestion color in registration page (Search Address Feild)
.marqAuto {mark{background: transparent;color: red; padding:0px; text-transform: capitalize; font-style: italic; font-weight: 500;}}
.pagination {
	li {
		a {
			color: #5b5b5b;

			&:hover {
				color: $primary;
				background: white
			}

			&.active {
				background: $primary;
				color: white
			}
		}
	}
}

.nowrap {
	white-space: nowrap !important;
}

.page-title {
	font-size: 1.25rem;
	font-weight: 400;
}

.section-title {
	font-size: 1.1rem;
	font-weight: 400;
}

.banner-row {
	width: 110px;
	vertical-align: top;
}

.banner-row-sm {
	width: 80px;
	vertical-align: top;
}

.banner-row2 {
	width: calc(100% - 100px);
}

.card {
	border: solid 1px $border-color;
	position: relative;

	&.cardbg {
		background: $bg_card;
		border: none;
	}

	&.no-border {
		border: none;
	}

	.card-legend {
		position: absolute;
		top: -12px;
		display: inline-block;
		padding: 0 3px;
		left: 10px;
		background: $white;

		&.bg-gray {
			background: -moz-gradient(to top, rgba(#ededed, 1) 50%, rgba($white, 0) 0%);
			background: -webkit-gradient(to top, rgba(#ededed, 1) 50%, rgba($white, 0) 0%);
			background: linear-gradient(to top, rgba(#ededed, 1) 50%, rgba($white, 0) 0%);
		}
	}

	.profile-image {
		width: 42px;
		height: 42px;
		border-radius: 50%;
	}

	.profile-image-sm {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.profile-image-md {
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}

	&.patient-red {
		background-color: #ffe6e8;
		border-color: #af4a53;
		color: $default;
	}
}

.popover {
	z-index: 99995 !important;
}

.hidefile {
	position: relative;

	input[type="file"] {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		min-height: 100%;
		cursor: pointer;
		opacity: 0;
		z-index: 9;
	}
}

.heading5 {
	font-size: 1.1rem;
	font-weight: 400;
}

.rounded-top-left-0 {
	border-top-left-radius: 0px !important;
}

.rounded-top-right-0 {
	border-top-right-radius: 0px !important;
}

.rounded-bottom-left-0 {
	border-bottom-left-radius: 0px !important;
}

.rounded-bottom-right-0 {
	border-bottom-right-radius: 0px !important;
}

/* #################### Tab ######################################## */
.nav-tabs {
	.nav-item {
		margin-bottom: -2px;

		.nav-link {
			padding: 0.25rem 1rem;
			background-color: $bg_alice_blue;
			color: #6a6a6a;
			margin-right: 10px;
			border: solid 1px $border-color;
			border-radius: 0;
			border-bottom: none;
			position: relative;
			transition-duration: 0s;

			&.active {
				background-color: #8b8b8b;
				color: $white;
				transition-delay: 0s;
				border-top-width: 0.175rem;
				border-top-color: $primary;
				bottom: 1px;
				border-left: 0;
				border-right: 0;
				transition-duration: 0s;
				
			}

			&:last-child {
				margin-left: 0px;
			}
		}
	}
}

.nav-tabs-content {
	.tab-pane {
		border: solid 1px $border-color;
		padding: 0.7rem 0.7rem 0;
	}
}

/* #################### BG Color  ############################################ */
.bg-dark-gray {
	background-color: $bg_dark;
}

.bg-gray {
	background-color: $bg_grey;
}

.bg-light-gray {
	background-color: $bg_lightgrey;
}

.bg-primary {
	background-color: $primary !important;
}

.bg-success {
	background-color: $success !important;
}

.bg-warning {
	background-color: $warning !important;
}

.bg-table-grey {
	background-color: #8b8b8b
}

/* #################### Text  ############################################ */
.font-weight-semibold {
	font-weight: 500 !important;
}

.text-primary {
	color: $primary !important;
}

.text-yellow {
	color: $yellow !important;
}

a.text-primary {
	color: $primary !important;

	&:hover,
	&:focus,
	&:active {
		color: $primary-hover !important;
	}
}

.text-black {
	color: $black !important;
}
.text-success {
	color: $success !important;
}

.text-dark {
	color: $bg_dark !important;
}

a.text-dark {
	color: $bg_dark !important;

	&:hover,
	&:focus,
	&:active {
		color: $primary !important;
		text-decoration: none !important;
	}
}

.text-warning {
	color: $warning !important;
}

/* #################### Alert ############################################ */
.alert.alert-grey {
	background-color: $bg_light_smoke;
	border-color: $bg_lightgrey;
}

/* #################### Patient Card ############################################ */
.patient-card {
	padding: 0.5rem 0.5rem 0.25rem;
	margin-bottom: 0.3rem;

	span {
		position: relative;
		border-radius: 50%;
		background: #fff;
		width: 26px;
		height: 26px;
		border: solid 1px #ccc;
		text-align: center;
		vertical-align: middle;
		display: inline-block;
		margin-left: 0.25rem;

		i,
		img {
			font-size: 15px;
			width: 15px;
			height: 15px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
		}

		&.red {
			border-color: $primary;
			color: $primary;
		}

		&.green {
			border-color: $success;
			color: $success;
		}

		&.purple {
			border-color: #9264a7;
			color: #9264a7;
		}

		&.dark {
			border-color: #333333;
			color: #333333;
		}

		&.yellow {
			border-color: #ff9c00;
			color: #ff9c00;
		}

		&.blue {
			font-size: 9px;
			font-weight: bold;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			line-height: 30px;
			border-color: #5c82be;
			color: #5c82be;
		}
	}
}

/* Extra Utilities */
.width100>div {
	width: 100%;
}


.Toastify__toast--success {
	color: #000 !important;
	background: white !important;

	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.87), 0 2px 15px 0 rgba(15, 4, 4, 0.05);

	.Toastify__toast-body {
		padding-left: 10px
	}

	.Toastify__progress-bar {
		background-color: #74a73260 !important;
	}

	&:before {
		content: '';
		width: 10px;
		height: 100%;
		background: #74a732;
		position: absolute;
		left: 0px;
		top: 0px
	}
}


.Toastify__toast--error {
	color: #000 !important;
	background: white !important;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.87), 0 2px 15px 0 rgba(15, 4, 4, 0.05);

	.Toastify__toast-body {
		padding-left: 10px
	}

	.Toastify__progress-bar {
		background-color: #dc354560 !important;
	}

	&:before {
		content: '';
		width: 10px;
		height: 100%;
		background: #dc3545;
		position: absolute;
		left: 0px;
		top: 0px
	}
}


.Toastify__close-button {
	color: #000 !important;
}

.filldata-zindex>label {
	z-index: 1000 !important;
}

.zindex {
	z-index: 999 !important;
}

.no-pointer {
	pointer-events: none !important;
}

.phnonemandatory {
	.special-label {
		&:after {
			position: relative;
			content: "*";
			color: red;
			padding-left: 5px;
			display: inline-block;
		}
	}
}

abbr {
	color: $text_muted;
}

.filldata {
	abbr {
		color: $text_muted;
		display: none;
	}
}

.encounterno {
	max-height: 300px;
	overflow-x: auto;

	a {
		white-space: break-spaces;
	}
}

.bed-click-popup {
	position: absolute;
	width: 100%;
	bottom: 20px;
	background: #fff;
	z-index: 99;
}

.fill-dark {
	fill: $bg_dark !important;
}

.fill-red {
	fill: $primary !important;
}

.cursor-p {
	cursor: pointer;
}

.font-weight-medium {
	font-weight: 500;
}



.badge-light {
	color: #212529;
	background-color: #fff;
	border: 1px solid #ccc;
	display: inline-flex;
	align-items: center;
	font-size: 12px;
	font-weight: normal;
}

.icon,
.ic-search {
	width: 14px;
}

.datePicker_timeIcon .react-datepicker__input-container {
	&::after {
		content: "\e84c";
		border: none;
		background: transparent;
	}
}

.access_role_accordion {
	.card-header {
		background: transparent !important;
		border: 0 !important;

		&.activeTab {
			position: relative;

			&::after {
				content: '\f004';
				font-family: "his-icon";
				display: block;
				position: absolute;
				right: 0;
				top: 0;
				right: 0.7rem;
				top: 0.7rem;
				font-size: 1.25rem;
			}

			&.active {
				&::after {
					content: '\f005' !important;
				}
			}
		}

		strong {
			font-size: 1.0125rem;
			font-weight: 500;
		}
	}

	.page_section_title {
		background-color: rgba($bg_lightgrey, 0.50);
		padding: 0.6rem 1.5rem .6em 4rem;
		font-weight: 500;
	}

	.page_section_title-new {
		padding: 0.6rem 1.5rem .6rem 10px;
		margin: 0px 10px 0px 98.5px;
		font-weight: 500;
		background: rgba($bg_lightgrey, 1);
		border-color: rgb(229, 229, 229);
		border-width: 1px 1px 0px 1px;
		border-style: solid;
	}

	.page_sub_section_title {
		padding: 0.6rem 1.5rem 0;
	}

	.nav-pills {
		.active {
			background: $primary !important;
		}

		a {
			color: $primary;
		}
	}
}





// For Vpass Page printing
.background-pass {
	background-color: #F7F7F7;
}

@page {
	margin: 0 !important;
}


.arrow_box {
	position: relative;
}

.arrow_box:after,
.arrow_box:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: rgba(0, 0, 0, .15);
	border-width: 10px;
	margin-left: -10px;
}

.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: rgba(255, 255, 255, 1);
	border-width: 11px;
	margin-left: -11px;
}



.disabled-datepicker {
	opacity: .5;
}

.table-scroll {
	position: relative;
	width: 100%;
	z-index: 1;
	margin: auto;
	overflow: auto;
	height: 650px;



}

.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	background: #666;
	color: #fff;
	z-index: 4;
}



.Toastify__toast-container {
	z-index: 99999 !important;
}

.text-overfloww {
	text-overflow: clip !important;
}

.input-group-prepend2 {
	display: flex;
	margin-bottom: 0.75rem;
	padding: .25rem .50rem;
	border: 1px solid #ccc;
	border-radius: .25rem 0 0 .25rem;
	border-right: 0px;
}


/* For ip bill */
.positionin {
	position: absolute;
	z-index: 999;
	width: 100%;
	background: white;
	left: 0px;
	top: 0px;
	background: #f4f4f4;
	transition: .8s ease all;
	height: 325px;
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
	border: 1px solid #ccc;
}

.positionout {
	position: absolute;
	left: 800px;
	background: #f4f4f4;
	transition: .8s ease all;
}

/* ip bill */



.erdashboard {
	margin-bottom: 16px;

	.left-strip {
		width: 5px;
		height: 100%;
		position: absolute;
		left: -1px;
		border-radius: 4px 0px 0px 4px;
		top: 0px;

		&.red {
			background: $primary;
		}

		&.green {
			background: $success;
		}
	}

	.right-button {
		background: #f4f4f4;
		color: #333;
		padding: 5px 15px;
		margin-right: -16px;
		border-radius: 4px 0px 0px 4px;
		height: 48px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.xl {
		font-size: 4em;
	}

	.small-card {
		overflow: hidden;
		padding: 0 10px 0px 10px;

		.top {
			background: #f4f4f4;
			text-align: center;
			padding: 5px;
			font-weight: 500;
			margin: -0rem -10px 0 -10px;
		}

		.bottom {
			padding: 5px;
			text-align: center;
			margin: -5px -10px;

			.heading {
				background: #fbfbfb;
				padding: 5px 0px;

				&.right {
					margin: 0px -10px 5px -5px
				}

				&.center {
					margin: 0px -5px 5px -5px
				}

				&.left {
					margin: 0px -5px 5px -10px
				}

			}
		}
	}

	.icons-card {
		div {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid;

			&.red {
				border-color: $primary !important;

				i {
					color: $primary !important;
					font-size: 12px;
				}
			}

			&.dark {
				border-color: $default !important;

				i {
					color: $default !important;
					font-size: 12px;
				}
			}

			&.purple {
				border-color: #9264a7 !important;

				i {
					color: #9264a7 !important;
					font-size: 12px;
				}
			}

		}
	}
}












.card-profile {
	width: 100%;
	background: #fff;
	position: relative;
	z-index: 1;
	margin: auto;
	border-radius: 8px;
	padding-bottom: 0px;
}

.toggled {
	transition: .5s ease all;
}

.cover-bg {
	width: 100%;
	height: 60px;
	background: #b4e2d6;
	background: url('../images/bg/5.png')no-repeat center;
	background-size: cover;

	&:after {
		content: '';
		background: #00000060;
		width: 100%;
		height: 63px;
		position: absolute;
		left: 0px;
	}
}

.user {
	&-info {
		padding-left: 20px;
		padding-top: 10px;
		width: auto;

		&-wrap {
			margin-top: -50px;
			display: flex;
			position: relative;
			z-index: 1;
			padding: 0 20px;
		}
	}

	&-name {
		font-size: 26px;
		font-weight: 400;
		color: #fff;
		margin-bottom: 10px
	}

	&-title {
		font-size: 16px;
		color: #333;
		display: block;
		margin-top: 10px
	}

	&-photo {
		height: 100px;
		width: 100px;
		border-radius: 50%;
		background: white;
		overflow: hidden;
		background-size: cover;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
	}

	&-bio {
		padding: 20px;

		p {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 40px;
		}
	}
}

.social {
	display: flex;
	justify-content: space-between;

	&-icons {
		display: flex;
	}

	a {
		display: block;
		height: 20px;
		color: #a9a9a8;
		margin-right: 13px;
		transition: all 0.3s ease-in-out 0s;

		&:hover {
			color: #47a398;
		}
	}

	.message {
		a {
			margin: 0;
		}
	}
}

.btn-no-arrows-content {
	height: 45px;

	&:after {
		border: 0px !important
	}

}

.py-6 {
	padding-top: 7.2rem;
}




// ipd -changes

.remover-border-ipd {
	.ant-select-selector {
		border: 0px !important;
		height: 30px !important;
	}
}

// grn
.selected-row {
	background-color: #f4f4f4
}

#accordion1 {
	.card {
		margin-bottom: 8px;
	}

	.card-header,
	.card-body {
		background: white;
		padding: 0px;
		font-weight: normal;
	}

	h6 {
		div {
			padding: 8px;
			background: #d24350;
			color: white !important;
			border-radius: 2px 2px 0px 0px;
		}

		.collapsed {
			background-color: white;
			color: #333 !important
		}
	}

	.text-dark {
		position: relative;

		&:after {
			position: absolute;
			content: '\f004';
			transform: rotate(180deg);
			right: 10px;
			top: 8px;
			font-family: "his-icon" !important;
		}

		&.collapsed {
			&:after {
				transform: rotate(0deg);
			}
		}
	}
}

#accordion2 {
	.card {
		margin-bottom: 8px;
	}

	.card-header,
	.card-body {
		background: white;
		padding: 0px;
		font-weight: normal;
	}

	h6 {
		div {
			padding: 8px;
			background: #d24350;
			color: white !important;
			border-radius: 2px 2px 0px 0px;
		}

		.collapsed {
			background-color: white;
			color: #333 !important
		}
	}

	.text-dark {
		position: relative;

		&:after {
			position: absolute;
			content: '\f004';
			transform: rotate(180deg);
			right: 10px;
			top: 8px;
			font-family: "his-icon" !important;
		}

		&.collapsed {
			&:after {
				transform: rotate(0deg);
			}
		}
	}
}


.worklist {
	.nav-link {
		padding: 10px 27px 10px 20px !important;
		font-weight: 500;
		background: #fff !important;
		border-radius: 8px 50px 0px 0px !important;
		border: 1px solid #ebecf0;

		&.active {
			border: 0px;
			background: #fff !important;
			color: $primary !important;

			.badgee {
				background: #da2f30;
			}
		}

		.badgee {
			background: #6a6a6a;
			border-radius: 50px;
			padding: 2px 7px;
			font-size: 10px;
			color: white;
			margin-top: -11px;
			display: inline;
		}
	}
}




.my_patient_height {
	height: calc(100vh - 300px);
	overflow-y: auto;
	background: white;
}
.ant-select-dropdown{z-index: 100002 !important;}

@media (min-width:768px) and (max-width:1191px) {
	.btn {
		font-size: 12px !important;
		padding: .25rem 1rem !important;
	}

	#main {
		min-height: calc(100vh - 100px) !important;
	}

	.my_patient_height {
		height: calc(100vh - 340px);
		overflow-y: auto;
		background: white;
	}

	body {
		font-size: 11px !important
	}
	.worklist {
		.nav-link {
			padding: 10px 16px 10px 10px !important;
			font-size: 11px;
			margin-right: 0px !important;

			i {
				font-size: 11px;
			}

			.badgee {
				font-size: 8px;
			}
		}
	}

	table {

		th,
		td {
			font-size: 11px !important;
		}

		.btnSize {
			width: auto !important;
			font-size: 0.65rem !important
		}
	}

	.barone {
		width: auto !important;
	}

	.worklist-header-search-icon {
		display: block
	}

	.worklist-header-search-text {
		display: none
	}

	.ant-modal-wrap {
		z-index: 10001 !important;
	}
}


.fixed-form {
	background: white !important;
	width: 100%;
	z-index: 999;
}



@media (min-width:1192px) {

	.worklist {
		.nav-link {
			padding: auto !important;

		}
	}

	.worklist-header-search-icon {
		display: none
	}

	.worklist-header-search-text {
		display: block
	}
}



.form-group {
	&.input-group {
		.errormsg {
			width: calc(100% + 2px) !important;
			height: calc(2rem + 2px) !important;
			top: -1px !important;
			left: -1px !important;
		}
	}
}














.custom-tooltip {
	position: absolute;
	width: 150px;
	height: 70px;
	border-radius: 4px;
	overflow: hidden;
	pointer-events: none;
	transition: opacity 1s;
	z-index: 999;
	background-color: #fff;
	border: 1px solid #ccc;
	box-shadow: 0px 0px 25px #ccc;
}

.custom-tooltip.ag-tooltip-hiding {
	opacity: 0;
}

.custom-tooltip p {
	margin: 5px;
	white-space: nowrap;
}

.custom-tooltip p:first-of-type {
	font-weight: bold;
}




.table-colonn {
	padding-left: 8px;

	position: relative;

	&:before {
		position: absolute;
		content: ':';
		left: 0px;
	}
}

// added 28Dec2021 by Mayur for ticket-14718
.arrow-handle {
	&:after {
		position: absolute;
		right: 10px;
		top: 50%;
	}
}

.rotate-down {
	transform: rotate(180deg);
}

.mayurtest .ag-theme-alpine .ag-cell-inline-editing .ag-react-container input {
	height: 32px !important;
	border: 0px !important;
	margin-top: 3px;
}

.border-new {
	border: 1.5px solid
}




















.croppie-container {
	width: 100%;
	height: 200px;
}

.croppie-container .cr-image {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	transform-origin: 0 0;
	max-height: none;
	max-width: none;
}

.croppie-container .cr-boundary {
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	z-index: 1;
	width: 100%;
	height: 100%;
}

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
	position: absolute;
	border: 2px solid #fff;
	margin: auto;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
	z-index: 0;
}

.croppie-container .cr-resizer {
	z-index: 2;
	box-shadow: none;
	pointer-events: none;
}

.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
	position: absolute;
	pointer-events: all;
}

.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
	display: block;
	position: absolute;
	box-sizing: border-box;
	border: 1px solid black;
	background: #fff;
	width: 10px;
	height: 10px;
	content: '';
}

.croppie-container .cr-resizer-vertical {
	bottom: -5px;
	cursor: row-resize;
	width: 100%;
	height: 10px;
}

.croppie-container .cr-resizer-vertical::after {
	left: 50%;
	margin-left: -5px;
}

.croppie-container .cr-resizer-horisontal {
	right: -5px;
	cursor: col-resize;
	width: 10px;
	height: 100%;
}

.croppie-container .cr-resizer-horisontal::after {
	top: 50%;
	margin-top: -5px;
}

.croppie-container .cr-original-image {
	display: none;
}

.croppie-container .cr-vp-circle {
	border-radius: 50%;
}

.croppie-container .cr-overlay {
	z-index: 1;
	position: absolute;
	cursor: move;
	touch-action: none;
}

.croppie-container .cr-slider-wrap {
	width: 75%;
	margin: 15px auto;
	text-align: center;
}

.croppie-result {
	position: relative;
	overflow: hidden;
}

.croppie-result img {
	position: absolute;
}

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/

.cr-slider {
	-webkit-appearance: none;
	/*removes default webkit styles*/
	/*border: 1px solid white; */
	/*fix for FF unable to apply focus style bug */
	width: 300px;
	/*required for proper track sizing in FF*/
	max-width: 100%;
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: transparent;
}

.cr-slider::-webkit-slider-runnable-track {
	width: 100%;
	height: 3px;
	background: rgba(0, 0, 0, 0.5);
	border: 0;
	border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: none;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: #ddd;
	margin-top: -6px;
}

.cr-slider:focus {
	outline: none;
}

/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/

.cr-slider::-moz-range-track {
	width: 100%;
	height: 3px;
	background: rgba(0, 0, 0, 0.5);
	border: 0;
	border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
	border: none;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: #ddd;
	margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
	outline: 1px solid white;
	outline-offset: -1px;
}

.cr-slider::-ms-track {
	width: 100%;
	height: 5px;
	background: transparent;
	/*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
	border-color: transparent;
	/*leave room for the larger thumb to overflow with a transparent border */
	border-width: 6px 0;
	color: transparent;
	/*remove default tick marks*/
}

.cr-slider::-ms-fill-lower {
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
}

.cr-slider::-ms-thumb {
	border: none;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: #ddd;
	margin-top: 1px;
}

.cr-slider:focus::-ms-fill-lower {
	background: rgba(0, 0, 0, 0.5);
}

.cr-slider:focus::-ms-fill-upper {
	background: rgba(0, 0, 0, 0.5);
}

/*******************************************/

/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
	position: absolute;
	bottom: 5px;
	left: 5px;
	z-index: 1;
}

.cr-rotate-controls button {
	border: 0;
	background: none;
}

.cr-rotate-controls i:before {
	display: inline-block;
	font-style: normal;
	font-weight: 900;
	font-size: 22px;
}

.cr-rotate-l i:before {
	content: '↺';
}

.cr-rotate-r i:before {
	content: '↻';
}





.module-item-list2 {
	background: #333;
	position: absolute;
	z-index: 9;
	width: 100%;
	height: 100%;
	overflow-y: auto;

	.parent {
		background: #5b5b5b;
		border-top: 1px solid #2b2b2b;

		a {
			color: #fff;
			padding: 7px 12px 7px 20px;
			display: block;
		}
	}

	.parent2 {
		text-decoration: none;
		display: block;
		padding: 7px 12px;
		background: transparent no-repeat !important;

	}
}

.text-danger,
.text-primary {
	&:hover {
		text-decoration: underline
	}
}


// form view component
.heights {
	max-height: calc(100vh - 300px);
}

.MuiDialog-paperWidthLg {
	width: 90%;
}

//@at-root

.emrborder {
	border: solid 1px #dee2e6;
	background: #f6f8fa;
	margin-bottom: 20px;

	.divs {
		padding: 0.4rem 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

/* ############################ Time picker @y0c/react-datepicker ########################################*/

.customtimepicker {
	width: 100%;
	display: block !important;

	.picker {
		display: block !important;
	}

	.picker-input__text {
		height: calc(2rem + 0px) !important;
		border: 0px !important;
		padding: 0 0.55rem 0 0.55rem !important;
		background: transparent !important;
		font-size: 14px;
	}
}

.picker__container {
	button {
		background: transparent !important;
		border: 0 !important
	}

	svg {
		width: 30px !important;
		height: 30px !important;
	}

	.time-input__down {
		border: 0px !important
	}

	.time-input__up {
		border: 0px !important
	}

	.time-input__text {
		border: 0px !important;

		input {
			font-size: 20px
		}
	}
}

/// for appointment page
.newphoneMandatory {
	width: calc(100% - 100px) !important;

	.form-control {
		border: 0px !important;
		border-radius: 0px !important;
	}
}

#root2>div {
	width: 100vw;
	height: 100vh;
	background: white;
	position: absolute;
	top: 0;
	z-index: 9999;
	padding: 10px;
}


// doctor schedule by sarfaraz
// doctor specific css

.gap {
	gap: 5px;
}

.width {
	width: 50px;
	height: 50px;
}

.trash-icon-size {
	width: 18px;
	height: auto;
}

// .table-eye-icon{
//     width:13px;
//     height:auto;
// }
.fix-width {
	min-width: 100px;
}

// cancel slot
.cancel-slot {
	padding: 0.25rem 0.15rem !important;
}

.btn-size {
	font-size: 0.875rem !important;
}

// create schedule css

.session-table {
	tbody {
		td {
			padding: 0.5rem 0.75rem;

			&.slot_cell {
				padding: 0rem !important;

				// background-color: #ededed !important;
				.bg_cell {
					background-color: #ededed !important;
					padding: 0.5rem 0.75rem;
					margin-left: 0px;
				}
			}

			.sub_row {
				padding: 0.5rem 0.75rem;
				margin-left: 0px;
			}
		}

	}
}

.header_row {
	text-align: left;
	text-transform: uppercase;
	vertical-align: middle !important;
	font-weight: 500;
	font-size: .95rem;
	background: #8c8c8c;
	color: #fff;


	.col_header {
		border-right: solid 1px #e5e5e5;
		padding: 0.3rem 0.5rem;
	}
}

.no_slotrow {
	background-color: #fff;
	font-size: .95rem;
	color: #333;

	.col_noslot {
		border: solid 1px #e5e5e5;
		padding: 0.3rem 0.5rem;

		&.cell_noslot {
			text-align: center;
		}
	}
}

.slotrow {
	background-color: #fff;

	.col_slotall {
		border: solid 1px #e5e5e5;
		padding: 0.3rem 0.5rem;
	}

	.col_slotall_head {
		background-color: #ededed;
		border: solid 1px #e5e5e5;
		padding: 0.3rem 0.5rem;
	}

	.right_border {
		border-right: solid 1px #e5e5e5;
	}

	.slots_middle_border {
		border-right: solid 1px #e5e5e5;
	}

	.right_border_last {
		border-bottom: solid 1px #e5e5e5;
	}

}

.inside_table {
	width: 90%;

	tbody {
		tr {
			td {
				vertical-align: middle;
				border: none;
				text-align: center;
				padding: 0.25rem;
			}
		}
	}
}




//*********************** service Appointment and Schedule css ******************************

.service_sched_table {
	width: 100%;

	thead th {
		padding: 0.3rem 0.5rem;
		border: none;
		font-weight: 500;
		color: #333;
		vertical-align: top;
	}

	tbody tr td {
		vertical-align: middle !important;

		padding: 0.5rem 0.75rem;
		color: #333;
	}
}

.bg-gray-container {
	background-color: #f4f4f4;
	border: solid 1px #f4f4f4;
	border-radius: 5px;
	padding: 0.5rem 0.5rem;
}

.large-modal-custom {
	min-width: auto !important;
	max-width: none !important;
	width: 85% !important;
	//min-height: auto !important;

	.modal-header {
		padding: 1.5rem 1.5rem 0rem 1.5rem !important;
		font-size: 1.2rem;
	}

	.modal-body {
		padding: 1rem 2rem !important;
	}
}

// package details modal css
.packagedet-accordion {
	.package_acc_cardhead {
		background-color: #fff;
		padding: 0.5rem 1rem;
		color: #333;
		text-transform: capitalize;
		position: relative;
		cursor: pointer;


	}

	// .arrow-pop-acc_pack {
	//         position: absolute;
	//         top: 30%;
	//         right: 20px;
	//         height: 14px;
	//         width: 13px;
	//         color: #666666 !important;
	//         transition: all 0.3s ease;
	//         transform: rotate(90deg);
	//     }

	//     [aria-expanded="true"] .arrow-pop-acc_pack {
	//         position: absolute;
	//         top: 30%;
	//         right: 20px;
	//         height: 14px;
	//         width: 13px;
	//         color: #666666 !important;
	//         transition: all 0.1s ease;
	//         transform: rotate(270deg);
	//     }

	.custom_angle {
		position: absolute;
		top: 30%;
		right: 20px;
		color: #666666 !important;
		transition: all 0.3s ease;

	}

	[aria-expanded="true"] .custom_angle {
		position: absolute;
		top: 30%;
		right: 20px;
		color: #666666 !important;
		transition: all 0.1s ease;
		transform: rotate(180deg);
	}

	.card-body {
		padding: 0.75rem 0.75rem;
	}
}


.gray-banner-card {
	background-color: #ededed;
	padding: 0.75rem 0.75rem;
	border: solid 1px #cccccc;
	border-radius: 5px;

	.banner-colwidth {
		color: #666666;
		width: 50px;
	}
}

// end doctor schedule by sarfaraz


.cke_editor_editor_dialog {
	z-index: 100000 !important
}

.cke_dialog {
	border: 1px solid #ccc !important
}