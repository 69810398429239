header{max-height:120px; position: relative; z-index: 991; display:initial;
	.logo{
		width: auto;
		height: auto;
		max-width: 300px;
		max-height: 50px;
		margin-top: 5px;
    display: flex;
    align-items: center;
    overflow: hidden;
	}
	

	.user-info{padding-top: 5px;
		a{color:$default; line-height: 1.35;}
		.profile-image {width: 42px; height: 42px; border-radius: 50%;}
	}
	.poweredby {border-left: 1px solid #eee; margin:5px 0px 0px 10px; color: #666; font-size: 0.8rem;
		a{display:inline-block;}
		img{max-width: 78px; margin-left: 10px;}
	}
	
	.navbar{background:$bg_dark; margin-top:6px; padding:0px; position: static; top:0px; z-index: 9999;	margin-left: -10px; margin-right: -10px;
		ul{margin:0 0 0 60px; padding:0; display:block;
			li{display:inline-block; position:relative; font-size:0; z-index:1; margin:.6rem .2rem;

				a{margin:0; padding:0.4rem 2rem; border-radius: 0.4rem; font-weight: 400; font-size: .9rem; line-height:1; color:$white; border: solid 0.11rem #ccc;
					text-decoration:none; display:block;  position:relative; outline:none; transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
					&:focus{box-shadow:none!important}
					&:hover{background:$primary; border-color: $primary;}
					&.btn-sm.btn-secondary{border-color:$primary!important; background-color:$white!important; color: $default;  font-size: 0.85rem; 
						
						&.active{color: white!important; background-color:$primary!important; 
						.text-danger{color:white!important}
						.font-weight-bold{font-weight:normal!important;}
						}
					}
				
				}
				&:hover{
					.activee{display:flex; cursor:pointer}
				}
				.activee{position: absolute;display:none; width:20px; border-radius:50%; height:20px; z-index:9; background: #ffffff;top:4px; right:5px;justify-content:center; align-items:center;
				i{font-size:12px}
				}
			}
		}
	}
	
}
#fixed-magic{
background:white !important;width:100%; z-index:99; padding-left: 25px;
}
.navbar{transition: .3s ease-in-out all;}
.fixed-header{
	position: fixed!important;width:100%; z-index:99; top: 0; margin:0px!important; left:0px; transition: .3s ease all;
	}
