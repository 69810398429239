// cssd master
.gray-card-cssd {
    background-color: #f4f4f4 !important;
    padding: 0.75rem 0.5rem !important;
    border: none !important;
}

.cssd-tab-container {
    padding: 0.75rem 0.75rem !important;
    border: solid 1px #cccccc;
    border-radius: 0px;
}

.cssd-gray-table {
    thead {
        tr {
            th {
                text-align: left !important;
            }
        }
    }
}

.small-custom-modal {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    .ant-modal-header {
        padding: 1rem 1.5rem 1rem 1.5rem !important;
        font-size: 1.2rem;
        border-bottom: none !important;
    }

    .ant-modal-body {
        padding: 1rem 1.5rem !important;
    }

    .ant-modal-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 1rem 1.5rem !important;
        border: none;
    }
}

.medium-custom-modal {
    overflow-y: auto !important;
        overflow-x: hidden !important;
        min-width: auto !important;
        max-width: none !important;
        width: 65% !important;
        //min-height: auto !important;
    .ant-modal-header {
        padding: 1rem 1.5rem 1rem 1.5rem !important;
        font-size: 1.2rem;
        border-bottom: none !important;
    }

    .ant-modal-body {
        padding: 1rem 1.5rem !important;
    }

    .ant-modal-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 1rem 1.5rem !important;
        border: none;
    }
}

// large modals
.large-custom-modal {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    min-width: auto !important;
    max-width: none !important;
    width: 85% !important;
    //min-height: auto !important;

    .ant-modal-header {
        padding: 1rem 1.5rem 1rem 1.5rem !important;
        font-size: 1.2rem;
        border-bottom: none !important;
    }

    .ant-modal-body {
        padding: 1rem 1.5rem !important;
    }
}

//timeline modal css
.cssd_timeline {
    margin: 0px;
    padding: 0px;
    list-style: none;
    width: 100%;
    float: left;
    position: relative;
    flex-direction: column;

    li {
        position: relative;
        float: left;
        width: 100%;
        padding-bottom: 1rem;
        line-height: 1;

        i {
            width: 0.9rem;
            height: 0.9rem;
            background: #a3a3a3;
            border-radius: 50%;
            display: block;
            float: left;
            margin-right: 0.5rem;
        }

        &.completed i {
            background-color: #00aa4c;
        }
        &.completedin-time i {
            background-color: #fd52e8;
        }
        &.inprogress i {
            background-color: #f39125;
        }
        &.na i {
            background-color: #fff;
            border: solid 1px #808080
        }
        &.completed-late i {
            background-color: #d24350;
        }
    }
}

.d_timeline_cssd {
    margin: 0px;
    padding: 0px;
    list-style: none;
    width: 100%;
    float: left;
    position: relative;

    li {
        position: relative;
        float: left;
        width: 100%;
        padding-bottom: 20px;

        .content {
            width: 44%;

            &.left {
                float: left;
                text-align: right;
            }

            &.right {
                float: right;
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 0.9rem;
            height: 0.9rem;
            border-radius: 50%;
            background: #a3a3a3;
            left: calc(50% - 0.45rem);
        }

        &:last-child {
            padding-bottom: 0px;
        }

        &.completed::before {
            background-color: #00aa4c;
        }

        &.inprogress::before {
            background-color: #f39125;
        }

        &.completed-late::before {
            background-color: #d24350;
        }
        &.completedin-time:before {
            background-color: #fd52e8;
        }

        &.arrow::after {
            content: '';
            display: block;
            position: absolute;
            left: calc(50% - 0.4rem);
            top: 50%;
            width: 0px;
            height: 0px;
            border-left: 0.4rem solid transparent;
            border-right: 0.4rem solid transparent;
            border-top: 0.6rem solid #a3a3a3;
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: calc(50% - 0.05rem);
        width: 1px;
        height: calc(100% - 1rem);
        border-left: dotted 0.1rem #a3a3a3;
        top: 10px
    }

}

.dot-icon-padd{
padding:2px 5px 0px 10px;
}