.report_lims{
    .responsivelogo{
        width:160px;
        height:auto;
    }
    .banner_container{
        border: solid 1px #5c5c5c;
        padding: 10px;
        .banner_heading{
            color:#333;
        }
        .banner_detail{
            color:#333;
            font-weight: 600;
        }
    }
    .clin_head{
        font-weight: 600;
        text-transform: uppercase;
    }
        .table-container {
            width: 100%;
            overflow-x: auto;
        }
    
        .responsive-table-report {
            width: 100%;
            border-collapse: collapse;

            th{
                background-color: #fff;
                font-weight: bold;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                padding: 0.5rem 0.65rem;
                text-align: left;
                &:first-child{
                    border-left: 1px solid #ddd;
                }
                &:last-child {
                    border-right: 1px solid #ddd;
                }
            }
            td{
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                padding: 0.4rem 0.65rem;
                text-align: left;
                &:first-child {
                    border-left: 1px solid #ddd;
                }
                
                &:last-child {
                    border-right: 1px solid #ddd;
                }
            }
        }

        .patient-responsive-table-report {
            width: 100%;
            border-collapse: collapse;
            border: none;

            td {
                padding: 0.1rem 0.65rem;
                text-align: left;
            }
        }

        .patient-culture-responsive-table-report {
            width: 80%;
            border-collapse: collapse;
            border: none;

            td {
                padding: 0.1rem 0.65rem;
                text-align: left;
            }
        }

        .service-report-text {
            font-size: 15px;
            font-weight: 800;
            padding: 0.1rem 0.65rem;
        }

        .reamrks-padding {
            padding: 0.1rem 0.65rem;
        }

        .td-font-weight {
            font-weight: 400;
        }

        .td-bold-font-weight {
            font-weight: 450;
        }

        

        .margin-report-bottom {
            margin-bottom: 20px;
        }

        .end-report-text {
            font-size: 15px;
            font-weight: 800;
        }
        
        .processing_facility {
            white-space: nowrap; 
            overflow: hidden; 
            text-overflow: ellipsis; 
            width: 220px;
        }
    
        @media (max-width: 768px) {
    
            .responsive-table-report th,
            .responsive-table-report td {
                white-space: nowrap;
            }
        }
}