//lis css
.listable {
    tr {
        th {
            text-align: left !important;
            vertical-align: top !important;
        }
    }
}

.organismmattable {
    tr {
        th {
            background-color: #fff !important;
            color: #333;
            text-align: left !important;
            border: solid 1px #ccc;
            &:last-child {
                border-right: solid 1px #ccc !important;
            }
        }
    }
}

.listable {
    tr {
        th {
            text-align: left !important;
        }
        td {
            padding: 0.65rem 0.5rem !important;
        }
    }
}

.listrans {
    .heig {
        height: calc(100vh - 200px);
        background: white;
    }

    .qmstable {
        width: 100%;

        th {
            position: sticky;
            top: 0px;
            color: #333 !important;
            background: white;
            z-index: 1;
            font-weight: 500 !important;
            font-size: 14px;
            padding: 12px;
        }

        tr:hover {
            background: #fae5e5;
        }

        td {
            padding: 3px 12px;
        }

        tr {
            position: relative;
            color: #5b5b5b !important;
            font-weight: 400 !important;
            font-size: 14px;
            padding: 8px !important;
            background: white;
            border-bottom: 1px solid #ebecf0;
            // box-shadow: 0px 0px 25px rgb(224 221 221 / 25%);
        }

        .qms-table-icons {
            width: 22px;
            height: auto
        }

        .text-dark {
            color: #333 !important;
        }

        .form-group.input-group .form-control~.input-group-addon {
            z-index: 0;
        }

        .width_align_last {
            min-width: 140px;
        }
    }

    .btn-custom {
        background-color: #fff;
    }

    .forward-pop {
        .ant-popover-inner {
            .dropitem {
                padding: 0.45rem 1.5rem !important;
                border-bottom: 1px solid #eee;
            }
        }
    }

    .ant-pagination-item {
        margin-right: 3px;

        &.ant-pagination-item-active {
            background: #d24350;
            color: #fff;
        }
    }

    .btn-outline-secondary {
        background-color: #fff;

        &:hover {
            background-color: #fff !important;
        }
    }

    .btn-outline-secondary:focus {
        box-shadow: none !important;
        background-color: #fff !important;
    }

    .btn-outline-primary:focus {
        box-shadow: none !important;
        background-color: #fff !important;
        color: #d24350 !important;
    }
}

//Accordion table css

table.fold-table {
    width: 100%;
    background-color: #fff;

    .mainhead {
        background-color: #fff;

        th {
            color: #333333;
            font-weight: normal;
            padding: 0.35rem 0.5rem !important;
            vertical-align: top;
            font-size: 0.93rem;
            border-bottom: solid 1px #eaeaea;
            font-weight: 500;
            border-right: none !important;

            .filter-icon-custom {
                width: 13px;
                height: auto
            }
        }
    }

    tbody {
        tr.view {
            td {
                cursor: pointer;
                color: #333;
                font-weight: 400;
                background-color: #fff;
                border: none;
                border-bottom: solid 1px #eaeaea;
                padding: 0.35rem 0.55rem !important;
                font-size: 0.9rem;

                // &:first-child {
                //   position: relative;
                //   padding-left: 25px !important;

                //   .arrowr {
                //     position: absolute;
                //     top: 30%;
                //     left: 8px;
                //     height: 14px;
                //     width: 13px;
                //     color: #666666 !important;
                //     transition: all 0.3s ease;
                //   }
                // }

                &:last-child {
                    padding: 0.35rem 0rem 0.35rem 0.35rem !important;
                }

                .phone-icon-custom {
                    width: 14px;
                    height: auto;
                }

                .status-badge {
                    width: 31px;
                    flex: 0 0 30px;
                    height: 16px;
                    display: inline-block;
                    text-align: center;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 11px;
                    font-weight: 500;
                    margin-right: 10px;
                    line-height: 17px;

                }

                .dots-padding {
                    padding: 9px 11px 9px 0px;
                }

                .dots-icon {
                    width: 12px;
                    height: 17px;
                }
            }

            &:hover {
                background: #ccc;
            }
        }

        tr.view.open {
            background: #ebebeb;
            color: #333;

            td {
                background-color: #fbe7e8;

                &:first-child {
                    &:before {
                        transform: rotate(90deg);
                        color: #333;
                        top: 50%;
                    }

                    .arrowr {
                        transform: rotate(90deg);
                    }
                }

                border-bottom: solid 1px #fff !important;
            }
        }

        tr.fold {
            display: none;

            th {
                color: #fff;
                font-weight: normal;
                border: solid 1px #dedede;
                padding: 0.3rem 0.75rem !important;
                font-size: 0.9rem;
                vertical-align: top;

                .filter-icon-custom {
                    width: 13px;
                    height: auto
                }
            }
        }

        tr.fold.open {
            display: table-row;
            background-color: #fbe7e8;

            td {
                padding: 0rem 0.5rem 0.75rem 0.5rem;
                border-bottom: solid 1px #eaeaea;

                .roundv-icon {
                    width: 19px;
                    height: 19px
                }

                .v-width {
                    min-width: 29px;
                    padding-right: 10px;
                    ;
                }

                .flag-icon {
                    width: 16px;
                    height: auto;
                }

                .dots-icon {
                    width: 12px;
                    height: 17px;
                }

                .dots-padding-in {
                    padding: 0px 4px 0px 5px;
                }
            }
        }
    }
}

.rowtable {
    width: 100%;

    thead {
        background-color: #8b8b8c;
    }

    tbody {
        background-color: #fff;
        border: solid 1px #ede4e4;

        tr {
            >td {
                color: #333;
                border: solid 1px #ede4e4;
                padding: 0.4rem 0.7rem !important;
                font-size: 0.9rem;
            }
        }
    }

    .ant-select-selector {
        border: none !important
    }
}

.fold-content {
    .rowtable {
        th {
            .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #fff !important;
            }
        }

        tbody {
            tr {
                td {
                    padding: 0.4rem 0.7rem !important;
                    font-size: 0.9rem;

                    span {
                        font-size: 0.9rem;
                    }

                    label {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    .btn-outline-primary {
        background-color: #fff;

        &:hover {
            background-color: #d24350 !important;
            border-color: #d24350 !important;
            color: #fff;
        }
    }
}

//investigation popup table
.invest-tablepop {
    tr {
        th {
            color: #d24350;
        }

        td {
            text-align: center;
        }
    }
}

//lis modal
.lis-modal {
    .ant-modal-header {
        border-bottom: none !important;
        padding: 15px 20px;
    }

    .ant-modal-body {
        padding: 15px 20px;
    }
}

.managelabtable {
    width: 100%;

    th {

        color: #333 !important;
        background: white;
        font-weight: 500 !important;
        font-size: 0.93rem;
        padding: 0.35rem 0.75rem;
        z-index: 1;
    }

    // tr {
    //     position: relative;
    //     color: #5b5b5b !important;
    //     font-weight: 400 !important;
    //     font-size: 14px;
    //     padding: 0.35rem 0.75rem;
    //     background: white;
    //     border-bottom: 1px solid #ebecf0;
    // }
    tbody tr {
        &:hover {
            td {
                background-color: #f2f2f2;
            }
        }
    }

    td {
        color: #333 !important;
        background: white;
        font-size: 0.9rem;
        padding: 0.35rem 0.75rem;
    }

    .dots-icon {
        width: 12px;
        height: 17px;
    }
}

.lab-table {
    width: 100%;

    thead {
        th {
            background-color: #efefef !important;
            color: #333333 !important;
            border-top: solid 1px #bfbfbf;
            border-bottom: solid 1px #bfbfbf;
            font-size: 0.93rem !important;
            border-right: none !important;
            text-align: left !important;
            height: 54px;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: solid 1px #bfbfbf !important;
            }

            &.no-display {
                color: #efefef !important
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 0.55rem 0.5rem 0.55rem !important;
                border: none !important;

                &.investigation-cell {
                    vertical-align: top !important;
                    padding-left: 0px !important;
                }

                &:last-child {
                    border-right: none !important;
                }

                &:first-child {
                    border-left: none !important;
                }
            }
        }
    }

    &.left-table {
        th {
            border-right: none !important;
            border-left: solid 1px #bfbfbf !important;
            height: 54px;

            &:last-child {
                border-right: none !important;
            }
        }

        tr {
            td {
                border-right: none !important;

                &:last-child {
                    border-right: none !important;
                }
            }
        }
    }
}
.lab_custom_table{
    @media screen and (max-width: 1480px) {
        .col-3 {
                flex: 0 0 16.666667%;
                max-width: 16.666667%;
            }
        
            .col-9 {
                flex: 0 0 83.333333%;
                max-width: 83.333333%;
            }
        }
        .left-table {
            th{
                position: sticky;
                top: 210px;
                z-index: 1000;
            }
            
        }
        // .without_banner{
        //     position: sticky;
        //     top: 70px;
        //     z-index: 10000;
        // }
}


.labresults-navpill {
    .nav-link {
        border-bottom: solid 1px #d3d1d1 !important;
        color: #333333;

        &:last-child {
            border-bottom: none !important;
        }

        &.active {
            background-color: #efefef !important;
            color: #333333;
        }
    }
}

.print-order-modal {
    border: 1px solid black;
    border-radius: 7px;
}

.lis-result-modal {
    .ant-modal-header-2 {
        border-bottom: none !important;
        padding: 15px 20px;
        text-align: center;

    }

    .ant-modal-body {
        padding: 15px 20px;
    }
}

.custom-button {
    cursor: default;
    text-align: center;
    font-size: large;
    color: red;
}

.custom-button:hover {
    cursor: pointer;
}

.custom-button-subdepartment {
    cursor: default;
    // text-align: center;
    // font-size: large;
    // color: red;
}

.custom-button-subdepartment:hover {
    cursor: pointer;
}

.wbc-count-chart-class {
    display: block;
    height: 235px;
    width: 500px;
}

.lab-box {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: solid 1px #707070;

    &.red-box {
        background-color: #e04a3c;
    }

    &.white-box {
        background-color: #ffffff;
    }

    &.yellow-box {
        background-color: #fffd54;
    }

    &.orange-box {
        background-color: #eeaa3c;
    }

    &.green-box {
        background-color: #a6ec9a;
    }
}

//lab dashboard missing css

.dashboardtable {
    &.fold-table {
        width: 100%;
        background-color: #fff;

        .mainhead {
            background-color: #8b8b8c;

            th {
                color: #fff;
                font-weight: normal;
                padding: 0.35rem 0.5rem !important;
                vertical-align: top;
                font-size: 0.93rem;
                border: solid 1px #eaeaea;

                .filter-icon-custom {
                    width: 13px;
                    height: auto
                }
            }
        }

        tbody {
            tr.view {
                td {
                    cursor: pointer;
                    color: #333;
                    font-weight: 400;
                    background-color: #fff;
                    border: none;
                    border-bottom: solid 1px #eaeaea;
                    padding: 0.35rem 0.55rem !important;
                    font-size: 0.9rem;

                    // &:first-child {
                    //   position: relative;
                    //   padding-left: 25px !important;

                    //   .arrowr {
                    //     position: absolute;
                    //     top: 30%;
                    //     left: 8px;
                    //     height: 14px;
                    //     width: 13px;
                    //     color: #666666 !important;
                    //     transition: all 0.3s ease;
                    //   }
                    // }

                    &:last-child {
                        padding: 0.35rem 0rem 0.35rem 0.35rem !important;
                    }

                    .dots-padding {
                        padding: 9px 7px 9px 0px;
                    }

                    .dots-icon {
                        width: 12px;
                        height: 17px;
                    }
                }

                &:hover {
                    background: #ccc;
                }
            }

            tr.view.open {
                background: #fff !important;
                color: #333;

                td {
                    background-color: #fff;

                    &:first-child {
                        &:before {
                            transform: rotate(90deg);
                            color: #333;
                            top: 50%;
                        }

                        .arrowr {
                            transform: rotate(90deg);
                        }
                    }

                    border-bottom: solid 1px #fff !important;
                }
            }

            tr.fold {
                display: none;

                th {
                    color: #333;
                    font-weight: normal;
                    //border: solid 1px #dedede;
                    border: none;
                    padding: 0.3rem 0.75rem !important;
                    font-size: 0.9rem;
                    vertical-align: top;

                    .filter-icon-custom {
                        width: 13px;
                        height: auto
                    }
                }
            }

            tr.fold.open {
                display: table-row;
                background-color: #fff;

                td {
                    padding: 0rem 0rem 0.75rem 0rem;
                    border-bottom: solid 1px #eaeaea;

                    .roundv-icon {
                        width: 19px;
                        height: 19px
                    }

                    .v-width {
                        min-width: 29px;
                        padding-right: 10px;
                        ;
                    }

                    .flag-icon {
                        width: 16px;
                        height: auto;
                    }

                    .dots-icon {
                        width: 12px;
                        height: 17px;
                    }

                    .dots-padding-in {
                        padding: 0px 4px 0px 5px;
                    }
                }
            }
        }
    }

    .rowtable {
        width: 100%;

        thead {
            background-color: #fbe7e8;

        }

        tbody {
            background-color: #fff;
            border: solid 1px #ede4e4;

            tr {
                >td {
                    color: #333;
                    //border: solid 1px #ede4e4;
                    border: solid 1px #fff;
                    padding: 0.4rem 0.7rem !important;
                    font-size: 0.9rem;
                }
            }
        }

        .ant-select-selector {
            border: none !important
        }
    }

    .fold-content {
        .rowtable {
            th {
                .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #fff !important;
                }
            }

            tbody {
                tr {
                    td {
                        padding: 0.4rem 0.7rem !important;
                        font-size: 0.9rem;

                        span {
                            font-size: 0.9rem;
                        }

                        label {
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }
    }

    .input-field {
        display: none;
    }

    /* CSS to show the input field when hovering over the .hover-input cell */
    .hover-input:hover .input-field {
        display: block;
    }
}

//manage result checkbox white
.bg-check {
    .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #fff !important;
    }

    &.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #fff !important;
    }
}

.add_buttons {
    .btn {
        position: absolute;
        z-index: 999;
        right: 10px;
        top: 3px;
        padding: 3px 8px;
    }
}

.Search_padding {
    .ant-input-search-button {
        padding: 3px 7px !important;
    }
}


.setCenter {
    width: 100%;
    height: 225px;
    margin: auto;
    border: 1px solid #767070;

}

.setsignature {
    margin: auto;
    margin-left: 1%;
}

.setTextCenter {
    margin: 105px;
    width: auto;
    height: auto;
    text-align: center;
}

//navtab padding
.tab-padding {
    .nav-item {
        .nav-link {
            &.active {
                padding-bottom: 12px !important;
            }
        }
    }
}

//culture result growthorg table

.growth-org-table {
    thead {
        background-color: #fff !important;

        tr {
            th {
                background-color: #fff !important;
                border: none !important;
                padding-left: 9px !important;
                &:last-child {
                    border-right: none !important;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                vertical-align: top !important;
                padding: 0.35rem 0.5rem 0.35rem !important;
            }
        }
    }
}

.disabled-link {
    pointer-events: none;
    color: gray;
  }
// Fo rreport LIS
.report-model {
    .ant-modal-header {
        padding: .5rem 1.75rem !important;
        display: none !important;
    }
    .ant-modal-body {
        padding-top: 5px !important;
    }
}  
#tatreporttable{
    th{
        position: sticky;
        position: -webkit-sticky;
        top:0px;
        z-index: 1 !important;
        background: #7d7d7d  !important;
    }
}
//for ant notfication toast
  .ant-notification {
      top: 55px !important;
      z-index: 11000 !important;
  }

.green-text {
    color: green;
  }

  .ant-btn.btn-outline-secondary {
      background-color: #fff;
    border-color: #5c5c5c !important;
        color: #5c5c5c !important;

      &:hover {
          background-color: #fff !important;
        border-color: #d24350 !important;
            color: #d24350 !important;
      }
  }

  .ant-btn.btn-outline-secondary:focus {
      box-shadow: none !important;
      background-color: #fff !important;
  }

//   .emrfrm .antInputItem.hasdata .ant-form-item-label,
//   .emrfrm .antSelectItem.hasdata .ant-form-item-label {
//     background: linear-gradient(0deg, #fff 50%, hsla(0, 0%, 100%, 0) 0) !important;
//   }

// .ant-form-item-control-input{
//     min-height: calc(2rem + 2px);
// height: calc(2rem + 2px);
//     line-height: 1.3 !important;
// }

.test-master-tabs{
    .ant-tabs-tab{
        padding: 0.25rem 1rem !important;
            background-color: #f0f4f7 !important;
            color: #6a6a6a !important;
            margin-right: 10px !important;
            border: solid 1px #ccc !important;
            border-radius: 0 !important;
            border-bottom: none !important;
            position: relative !important;
            transition-duration: 0s !important;
            
    }
    .ant-tabs-tab-active{
        background-color: #8b8b8b !important;
            color: #fff !important;
            transition-delay: 0s !important;
            border-top-width: 0.175rem !important;
            border-top-color: #d24350 !important;
            bottom: 1px !important;
            border-left: 0 !important;
            border-right: 0 !important;
            transition-duration: 0s !important;
            background: #8b8b8b !important;
    }
    .ant-tabs-nav{
        margin:0 !important;
    }
    .ant-tabs-content-holder{
        border:solid 1px #ccc;
    }
}

.clinicalfind_modal {
    width: 90% !important;
}

//lis tabe borderbottom

.lims-queue-overlay {position: absolute;background-color: #FFFFFF;top: 0;left: 0;z-index: 500;right: 0;bottom: 0;opacity: 0.8;}
.lims-queue-overlay-child {text-align: center;margin-top: 80px;}
.lock-style{background-color: #d24350 !important;font-size: 40px;margin-bottom: 10px;padding: 10px;color: #ffffff !important;border-radius: 10px;}
.lock-text {font-size: 18px;margin-bottom: 0;}
.lock-text-header{border-bottom: 1px solid #cccccc;}
.lims-sao{z-index: 501;position: inherit;}

.timeout-panel { position: fixed; bottom: 0; left: 0; right: 0; background-color: #ffffff; z-index: 999; }
.timeout-container{padding: 15px 30px 0 30px;text-align: center;}
.timeout-panel-header{font-size: 19px;}
.timeout-panel-body{font-size: 17px;}
.timeout-time-icon { font-size: 60px; text-align: right; color:#d24350 }
.timeout-time-info {text-align: left;}
.timeout-time-value {font-size: 60px;}
.invest-form-tab{padding-bottom: 150px;}

.print_worklist_modal{
  width: 90% !important;  
}

.hide-browse{
    position: absolute !important;
    right: 0;
    opacity: 0;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
 
}

//patient banner sticky in lab result
.pat_specific_ban {
    .white_stickybanner {
        padding-top: 10px;
        padding-bottom: 18px;
        position: sticky;
        position: -webkit-sticky;
        // top: 46px;
        top: 90px;
        background: #fff;
        z-index: 99;
    }
    .white_stickybanner_drawer {
        padding-top: 10px;
        padding-bottom: 18px;
        position: sticky;
        position: -webkit-sticky;
        top: 0px;
        background: #fff;
        z-index: 99;
    }
    .sticky_title {
        padding-top: 10px;
        position: sticky;
        position: -webkit-sticky;
        top: 46px;
        background: #fff;
        z-index: 100;
    }
}
//added for scroll inside drawer for manage lis orders
.scrollleftrightlisord {
    position: sticky;
    position: -webkit-sticky;
    top: 166px;
    height: 100%;

    .left-table {
        th {
            position: sticky;
            top: 166px;
            z-index: 1000;
        }

    }
    .stickyhead {
        th {
            top: 166px !important;
        }
    }
}
//end manage lis orders scroll drawer sticky

.labresult_tablepad {
    .lab-table{
        thead {
            &.stickyhead{
                th {
                        position: sticky;
                        position: -webkit-sticky;
                        top: 210px;
                        z-index: 1000;
                    }
            }              
        } 
    }
    

    @media screen and (max-width: 1480px) {
        tbody {
            tr {
                td {
                    padding: 0.55rem 0.15rem 0.55rem !important;

                }
            }
        }
    }
}

.scrollleftright {
    position: sticky;
    position: -webkit-sticky;
    top: 210px;
    height: 100%;
}
    //css for ordertimeline
.order-timeliness {
        ul {
            padding: 0px;
            margin-bottom: 0px;
            display: block;
            width: 100%;
            float: left;

            li {
                list-style-type: none;
                position: relative;
                width: 100%;
                float: left;
                min-height: 70px;

                .negativeflow {
                    margin-top: -19px;
                    padding-bottom: 20px;
                    display: block;
                    
                    .singlenegative {
                        // min-height: 40px;
                        padding-bottom: 3px !important;
                        &::after {
                            content: unset;
                        }
                    }
                    &:last-child {
                        padding-bottom: 0px !important;
                    }
                }

                .order-left {
                    width: 50%;
                    position: relative;
                    float: left;
                    padding-right: 50px;
                    text-align: right;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0px;
                        right: -7px;
                        background-color: #adadad;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        z-index: 9;
                    }
                    &.completed::before {
                        background-color: #00aa4c;;
                    }

                    &.inprogress::before {
                        background-color: #d24350;;
                    }

                    &.completed-late::before {
                        background-color: #ff8800;
                    }
                    &.pending::before {
                        background-color: #adadad;
                    }
                    &.revert::before {
                        background-color: #1b27d3;
                    }
                }

                .order-right {
                    width: 50%;
                    position: relative;
                    float: right;
                    text-align: start;
                    padding-left: 50px;
                }

                .order-status {
                    width: 50%;
                    position: relative;
                    padding-right: 50px;
                    border-bottom: 0.1rem dotted #0000ff;
                    text-align: left;
                }

                .status-line {
                    width: 50%;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: -7px;
                        transform: translate(0px, -6px);
                        background-color: #0000ff;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        z-index: 9;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    height: 100%;
                    border-left: 0.1rem dotted #adadad;
                }
                &:last-child::after {
                    height: 25%;
                }
            }
        }
}    

.coll_diss_row {
    background: #fffee3 !important;

    td {
        background: #fffee3 !important;
    }
}
.coll_diss_inter_facility_row {
    background: #E1F2F8 !important;

    td {
        background: #E1F2F8 !important;
    }
}
.outsourced-legend {
    width: 15px;
    height: 15px;
    display: inline-block;
    background-color: #fffee3;
    border: solid 1px #ccc;
}

.outsourced-text {
    font-size: 0.9rem;
    color: #333;
}

.inter-facility-legend {
    width: 15px;
    height: 15px;
    display: inline-block;
    background-color: #E1F2F8;
    border: solid 1px #ccc;
}

.inter-facility-text {
    font-size: 0.9rem;
    color: #333;
}

 //missedorder table

 .missedorder_table {
    thead {
        th {
            text-align: left !important;
        }
    }

    tbody {
        td {
            cursor: pointer;
            color: #333;
            font-weight: 400;
            background-color: #fff;
            border: none;
            border-bottom: solid 1px #eaeaea;
            padding: 0.35rem 0.25rem !important;
            font-size: 0.9rem;
        }
    }
 }

 .missed-order-alert-btn{
    position: relative;
    padding:0.25rem 0.45rem !important;
    .counter-badge{
        width:19px;
        height: 19px;
        border-radius: 50%;
        position: absolute;
        top:-10px;
        right:-10px;
        background-color: #fff;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#333;
        border: solid 1px #d24350;
    }
    padding: 0.5rem 0.75rem;
    border: solid 1px #cccccc;
    border-radius: 5px;
    }
    .label-delay{
        padding: 5px 7px;
        background-color:#d37784;
        color: #fff;
        border-radius: 5px;
        font-weight: 500;
        display: inline-block;

    }

.priority-edit-button {
    cursor: pointer;
    text-align: center;
}
    .gotopbtn {
        position: fixed;
        width: 40px;
        height: 40px;
        border: none;
        transform: rotate(270deg);
        background: #d24350 !important;
        bottom: 120px;
        right: 30px;
        text-decoration: none;
        text-align: center;
        color: white;
        font-size: 25px;
        border-radius: 5px;
        &:hover {
        background: #d24350 !important;
        }
        }

        .bulk-approval-drawer-body {
            padding: 20px;
            overflow: auto;
            height: 100%;
        }
        .investigation-counter {
            background-color: #ffffff;
            color: #000000;
            margin: 0 0 0 -80px;
            position: absolute;
            padding: 10px 20px 10px 20px;
        }

        .big-font-size {
            font-weight: 700;
            font-size: 20px;
        }

        .approve-popover-padding{
            margin-left: 11px;
            padding-left: 0;
        }

        .approve-popover-padding li:hover span {
            background-color: rgb(234, 226, 226);
            color: white; /* Optional: Change text color on hover */
        }

        .bulk-approve-button {
            cursor: pointer;
            color: #d24350;
            text-decoration: underline;
        }
        

        //red checkbox
        .red-checkbox {
            &.ant-checkbox-wrapper .ant-checkbox-inner {
            width: 1.5rem !important;
            height: 1.5rem !important;
            }
            .ant-checkbox-checked {
            .ant-checkbox-inner {
            border-color: #d24350 !important;
            background-color: #d24350 !important;
            width: 1.5rem !important;
            height: 1.5rem !important;
            &::after {
            border-color: #fff !important;
            width: 6.5px !important;
            height: 14px !important;
            position: absolute;
            display: table;
            border: 3px solid #fff;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
            opacity: 1;
            transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
            content: " ";
            }
            }
            }
            .ant-checkbox-disabled {
            .ant-checkbox-inner {
            opacity: 0.7;
            }
            }
            }
.machine-entry-count-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #9bfc91;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.manual-entry-count-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffce78;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.theme-row {
    .ant-radio-group {
        display: flex !important;
    }
}


.custom-popover-rerun {
 max-width: 400px !important;

}

.print_worklist_scroll{
    max-height: 600px;
    overflow-y: scroll;
}

@media print {
    .print-header {
        display: block;
        margin-bottom: 20px;
        font-family: 'Roboto, sans-serif';
        color: #6c757d;
        background: white;
        height: 70px;
        width: 98%;
        margin-left: 10px;
    }
    // .order-container {
    //     page-break-inside: avoid;
    // }
}

.print_worklist_print {
    page-break-inside: avoid;
}
.floatbulkapprovebtn {
    position: fixed;
    width: 40px;
    height: 40px;
    border: none;
    transform: rotate(270deg);
    background: #d24350 !important;
    bottom: 60px;
    right: 30px;
    text-decoration: none;
    text-align: center;
    color: white;
    font-size: 25px;
    border-radius: 5px;
    &:hover {
    background: #d24350 !important;
    }
    }
    .antibiogram-report-table{
        tr{
            th{
                border-top-right-radius: 0px !important;
                border-top-left-radius: 0px !important;
            }
        }
    }

    .result-view-loader .spinner {
        display: inline;
    }

    .culture-drawer-body {
        padding: 0px;
        overflow: auto;
        height: 100%;
    }

    .display-none-mange-lab-order {
       display: none;
    }

    .table.table-bordered.table-sm.c-table td { padding: 1px !important; }
    .select-white-tick{
        .ant-select-item-option-selected{
            svg{
                fill: #fff !important
            }
        }
    }
